/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable no-console */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { client, clientCall, client1 } from './client';
import { appConfig } from '../config/app-config';
import { CancelToken } from 'axios';
import { ProductPermissions } from '../constants/constants';
// import { PrintRes } from '../components/Stub/printEditPriceTagResponse';
import { priceTagdata } from '../components/Stub/PriceTagResponse';
//import { User } from '../types/types';
const QAToken =
  'eyJraWQiOiI3M1BNSjFNQm1ObkVUU3hPZFNqYWVibVRFK0tjZTRvdVwva1ZDT2VNTGgwbz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIydm42dGV0MmhxYmpmbW00ZzdrdDFlNWw4NyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoicmFjXC9yZWFkIiwiYXV0aF90aW1lIjoxNjM1NTQ1NTY3LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV84TWJPeUVZRzYiLCJleHAiOjE2MzU1NDkxNjcsImlhdCI6MTYzNTU0NTU2NywidmVyc2lvbiI6MiwianRpIjoiMWI4NjJmZDctZGE0Zi00ZTMwLWJmM2QtMGUwOGQwNDRmMmYwIiwiY2xpZW50X2lkIjoiMnZuNnRldDJocWJqZm1tNGc3a3QxZTVsODcifQ.qOF9aMMN_8nCia6JbsZmUy6wD96wsWPZmZVULqu3bsa7RDctmVH3gbTANOToA-U3xqNGSFL9rMrHU8YmAuEgI9NXtMxcZzOXJrVvV_Zlpq6Kb6FgIRtDOFzOGvuW41aOAzOxL4H7BjLOLzbbIkfWINBUME68NhXyP8ZMDrjP0pw75sqw2peIaMPILQqEJUARNiFaphrMRz267SUo2LIkLFxsq20rE_tgCTkFZ8n_zgUZ5ejJnM92VQkd-2hmp8SCdqalEI4r1Q1vva3mkR8kaLqN2Ef0b_XMj4bMXnbl1DNKPdyi48GknOFD81AonGXqW5hJFxe1LfTOB4H0kcryeA';
const TwoFactorBaseURL = appConfig.apiUrls.storemgmt;
const customerBaseURL = appConfig.apiUrls.customer;
const InventoryBaseUrl = appConfig.apiUrls.micro;
const getCurrentUserBaseURL = appConfig.apiUrls.menu;
//temp
const temporaryMenuURL = 'https://qa-menu-racpad.rentacenter.com/api';
const temporaryTwoFactorBaseURL =
  'https://qa-storemgmt-racpad.rentacenter.com/api';

export const insertAgreementLog = async (Payload: any) => {
  return await clientCall(
    `agreement/activity`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.customer,
    QAToken,
    true
  );
};

  export const featureFlagInfo = async (bodyobject: any) => {
   const TempAccessToken = QAToken;
   return await clientCall(
    `agreement/address/eventId`,
    { method: 'POST', body: bodyobject },
   appConfig.apiUrls.agreement,
   TempAccessToken
  );
  };
  
  export const getInventoryAuditHistory = async (StoreNum: any,offset: any) => {
    return await clientCall(
      `inventory/audit/history/${StoreNum}?offset=${offset}`,
      { method: 'GET' },
      InventoryBaseUrl,
      QAToken,
      true
    );
  };
  export const getInventoryAuditInfo = async (auditId: any) => {
    return await clientCall(
      `inventory/audit/${auditId}`,
      { method: 'GET' },
      InventoryBaseUrl,
      QAToken,
      true
    );
  };
  export const getEvent = async (Payload: any) => {
    return await clientCall(
      `agreement/delivery/info/127772959?appointmentId=${Payload}`,
      { method: 'GET' },
      appConfig.apiUrls.agreement,
      QAToken,
      true
    ); 
  };

  export const AppointmentAction = async (updatedeliverystatus: any) => {
    return await clientCall(
      `agreement/delivery/status/update`,
      { method: 'PUT', body: updatedeliverystatus },
      appConfig.apiUrls.agreement,
      QAToken,
      true
    );
  };

  export const LoadDeliveryreceipt = async (agreementId: string) => {
    return await clientCall(
      `agreement/delivery/receipt/${agreementId}`,
      { method: 'GET' },
       appConfig.apiUrls.agreement,
       QAToken,
       true
    );
  };
  export const InventoryServicing = async (value: any) => {

    return await clientCall(
    
  `inventory/service/update`,
    
     { method: 'POST', body: value },
    
     InventoryBaseUrl,
    
     QAToken,
    
     true
    
    );
    
    };

  export const getInventoryServiceSummmary = async (payload: any) => {
    return await clientCall(
      `inventory/service/list/${payload.identifier}?limit=${payload.limit}&type=${payload.type}&offset=${payload.offset}`,
      { method: 'GET' },
      InventoryBaseUrl,
      QAToken,
      true
    );
  };

  export const getServiceOptions = async (payload: any) => {
    try {
      return await client1(
        'inventory/package/list?isService=1',
        { method: 'POST', body: payload },
        InventoryBaseUrl,
        QAToken,
        true
      );
    } catch (err: any) {
      /* eslint-disable no-console */
      console.log(err);
      /* eslint-enable no-console */
      return 'Error';
    }
  };
  export const getInventoryServicingDetails = async (serviceNumber: any) => {
    return await clientCall(
      `inventory/service/details/${serviceNumber}`,
      { method: 'GET' },
      InventoryBaseUrl,
      QAToken,
      true
    );
  };

  export const postAppointment = async (Payload: any) => {
    return await clientCall(
      'agreement/delivery',
      { method: 'POST', body: Payload },
      appConfig.apiUrls.agreement,
      QAToken,
      true
    ); 
  };

  export const getTimeSlot = async (Payload: any) => {
    return await clientCall(
      'agreement/timeslots',
      { method: 'POST', body: Payload },
      appConfig.apiUrls.agreement,
      QAToken,
      true
    ); 
  };

  export const CoworkerRequired = async (Payload: any) => {
    return await clientCall(
      'agreement/address/eventId',
      { method: 'POST', body: Payload },
      appConfig.apiUrls.agreement,
      QAToken,
      true
    ); 
  };
  
  export const ManageInventoryAudit = async (auditId: any) => {
    return await clientCall(
      `inventory/audit/update`,
      { method: 'POST', body: auditId },
      InventoryBaseUrl,
      QAToken,
      true
    );
  };
  export const getInventoryAuditExplanation = async (inventoryNumber: any) => {
    return await clientCall(
      `inventory/audit/inventory/${inventoryNumber}`,
      { method: 'GET' },
      InventoryBaseUrl,
      QAToken,
      true
    );
  };

export const getCurrentUser = async () => {
  return await clientCall(
    `user/current`,
    { method: 'GET' },
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};
export const getUserPreferences = (productType: string) =>
  client(
    `users/current/preferences/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container
  );

export const getUserPermissions = (
  productType: string
): Promise<ProductPermissions[]> =>
  client(
    `users/current/permissions/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container
  );

export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,
    {
      method: 'PATCH',
      body: [payload],
      cancelToken,
    },
    appConfig.apiUrls.container
  );

export const getUserStores = () =>
  client(
    'users/current/stores?q=permissions[:includes]=RACPAD_DAP',
    { method: 'GET' },
    appConfig.apiUrls.container
  );
export const getInventorySearch = async (payload: any) => {
  // eslint-disable-next-line no-console
  console.log('payload', payload);

  return await clientCall(
    'inventory/search',

    { method: 'POST', body: payload },
    InventoryBaseUrl,
    QAToken,
    true
  );
};
export const getSearchParams = async () => {
  return await client1(
    `inventory/search-params/""`,

    { method: 'GET' },

    InventoryBaseUrl,

    QAToken,
    true
  );
};

export const getSalesInfo = async (salesLeadId: any) => {

  return await clientCall(

    `customer/saleslead/details/${salesLeadId}`,

    { method: 'GET' },

    customerBaseURL,

    QAToken,

    true

  );

};


export const GetCustomerByIdInfo = async (customerId: any,

  storeNumber: any) => {

  return await clientCall(

    `customer/${customerId}`,

    { method: "PUT", body: { storeNumber: storeNumber } },

    customerBaseURL,

    QAToken,

    true

  );

};
export const getPackageDropdown = async (payload: any) => {
  try {
    return await client1(
      'inventory/package/list',

      { method: 'POST', body: payload },
      InventoryBaseUrl,
      QAToken,
      true
    );
  } catch (err: any) {
    /* eslint-disable no-console */
    console.log(err);
    /* eslint-enable no-console */
    return 'Error';
  }
};

export const postbreakPackage = async (inventoryNumber: any) => {
  //  debugger
   // eslint-disable-next-line no-console
   console.log('inventoryNumber', inventoryNumber);
 
   return await clientCall(
     `/inventory/package/break`,
 
     { method: 'POST',body:inventoryNumber },
     InventoryBaseUrl,
     QAToken,
     true
   );
 
 };
export const getRadiusStore = async (storeNumber: any) => {
  return await clientCall(
    `radius-stores/${storeNumber}`,
    {
      method: 'GET',
    },
    TwoFactorBaseURL,
    QAToken,
    true
  );
};
export const PrintFaxSheet = async (storeNumber: any) => {
  /* eslint-disable no-console */

  console.log('QA TOKEN', QAToken);

  /* eslint-enable no-console */

  /* eslint-enable no-console */

  const TempAccessToken = QAToken;

  return await clientCall(
    `customer/store-search`,
    { method: 'POST' ,body:{storeNumbers:storeNumber }},

    customerBaseURL,

    TempAccessToken,

    true
  );
};

// export const LoadInventoryInformation = async (inventoryNumber: any, type?: any) => {
//   // eslint-disable-next-line no-console
//   console.log('invinforeq', inventoryNumber);
//   // eslint-disable-next-line no-console
//   console.log('type', type);

//   return await clientCall(
//     type === 'Y' ?
//       `inventory/${inventoryNumber}?isHeader=${'Y'}` :
//       `inventory/${inventoryNumber}`,
//     { method: 'GET' },
//     InventoryBaseUrl,
//     QAToken,
//     true
//   );
// };

export const LoadInventoryInformation = async (inventoryNumber: any, type?: any) => {
  // eslint-disable-next-line no-console
  console.log('invinforeq', inventoryNumber);
  // eslint-disable-next-line no-console
  console.log('type', type);

  return await clientCall(
      `inventory/${inventoryNumber}?storeNum=${window.sessionStorage.getItem('storeNumber')}`,
    { method: 'GET' },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const LoadIdleInventoryAudit = async (value: any) => {
  // eslint-disable-next-line no-console
  console.log('idleinvauditreq', value);
  return await clientCall(
    `inventory/idleaudit/${value.inventoryNumber}?limit=${value.limit}&offset=${value.offset}`,
    { method: 'GET' },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const LoadAgreementHistory = async (inventoryNumber: any) => {
  // eslint-disable-next-line no-console
  console.log('agrHistreq', inventoryNumber);
  return await clientCall(
    `inventory/agreements/${inventoryNumber}`,
    { method: 'GET' },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const GetRemoteLoginStatus = async (Payload: any) => {
  return await clientCall(
    `cashmgmt/duo/auth`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.cashmgmt,
    QAToken,
    true
  );
};

export const getActivities = async (inventoryNumber: any,setNumber:any) => {
  // eslint-disable-next-line no-console
  console.log('inventoryNumber', inventoryNumber);

  return await clientCall(
    `inventory/activities/${inventoryNumber}?setNumber=${setNumber}`,

    { method: 'GET' },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const saveInventoryInfo = async (payload: any) => {
  // eslint-disable-next-line no-console
  console.log('saveInvInfoPayloadreq', payload);
  return await clientCall(
    `inventory/information`,
    { method: 'POST', body: payload },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const updateInventoryStatus = async (payload: any) => {
  // eslint-disable-next-line no-console
  console.log('updateInventoryStatusPayloadreq', payload);
  return await clientCall(
    `inventory/updateInventoryStatus`,
    { method: 'POST', body: payload },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const updateTransfer = async (payload: any) => {
  // eslint-disable-next-line no-console
  console.log('updateTransferInventoryPayloadreq', payload);
  return await clientCall(
    `inventory/transfer`,
    { method: 'POST', body: payload },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const getInventoryStatusReasons = async (recovery:string) => {
  return await clientCall(
    `inventory/chargeoff/reason/${recovery}`,
    { method: 'GET' },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

// currently not using this.
export const getmenuandstore = async (EmployeeId: any) => {
  //const jwtToken = await getJWToken();
  //const decodedToken: any = jwtDecode(jwtToken);
  // eslint-disable-next-line no-console
  //console.log('decodedToken', decodedToken);
  const parameters = {
    coworkerId: EmployeeId,
    storesRequired: true,
  };
  return await client(
    'menu-and-stores',
    { method: 'POST', body: parameters },
    getCurrentUserBaseURL,
    QAToken
  );
};

//Authentication popup
export const getprofileaccess = async (Payload: any) => {
  return await clientCall(
    `store-access`,
    { method: 'POST', body: Payload },
    TwoFactorBaseURL,
    QAToken,
    true
  );
};

export const getPinDetails = async (Payload: any) => {
  return await clientCall(
    `coworker`,
    { method: 'POST', body: Payload },
    TwoFactorBaseURL,
    QAToken,
    true
  );
};

export const getsecondfactor = async (Payload: any) => {
  return await clientCall(
    `coworker-authentication`,
    { method: 'POST', body: Payload },
    TwoFactorBaseURL,
    QAToken,
    true
  );
};

export const getCurrentInfo = async () => {
  return await clientCall(
    `user/current`,
    { method: 'GET' },
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};
export const getCoworkerDetails = async (payload: any) => {
  return await clientCall(
    `menu-and-stores`,
    { method: 'POST', body: payload },
    //getCurrentUserBaseURL,
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};


export const getTransferReceiveGridDetails = async (storeNumber: any) => {

  return await clientCall(

    `inventory/transfer/list/${storeNumber}`,

    { method: 'GET' },

    InventoryBaseUrl,

    QAToken,

    true

  );

  // return resp;

};

export const updateTransferReceiveDetails = async (req: any) => {

  // eslint-disable-next-line no-console
  console.log('request received in user for prictagstatus', req);

  return await clientCall(

    'inventory/transfer/update',

    { method: 'POST', body: req },

    InventoryBaseUrl,

    QAToken,

    true

  );
}

export const getPackagePriceTagList = async (storeNumber:any,index:number) => {
  return await clientCall(
    `inventory/packagepricetag/list/${storeNumber}?index=${index}`,

    { method: 'GET' },
    InventoryBaseUrl,
    QAToken,
    true
  );

}

export const createPackage = async (payload:any) => {
  return await clientCall(
    `inventory/package`,
    { method: 'POST', body: payload },
    InventoryBaseUrl,
    QAToken,
    true
  );
}

export const getPackagePriceTag = async (payload: any) => {

  return await clientCall(
    `inventory/package/printpackage`,

    { method: 'POST', body: payload },
    InventoryBaseUrl,
    QAToken,
    true
  );

};


export const GetEmployeeID = async () => {
  return await clientCall(
    `user/current`,
    { method: 'GET' },
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};
export const GetRole = async (Payload: any) => {
  return await clientCall(
    `menu-and-stores`,
    { method: 'POST', body: Payload },
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};

export const getPricingClientCall = async (Payload: any) => {
  return await clientCall(
    'agreement/package-item/price',
    { method: 'POST', body: Payload },
    appConfig.apiUrls.agreement,
    QAToken,
    true
  ); 
};


export const getInventoryPriceTags = async (payload: any) => {
  return await clientCall(
    `inventory/pricetag`,
    { method: 'POST', body: payload },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const getExceptionReasonList = async () => {
  return await clientCall(
    `inventory/pricetag/exceptionreason`,

    { method: 'GET' },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const updatePriceTagException = async (payload: any) => {
  return await clientCall(
    'inventory/pricetag/exception',
    { method: 'POST', body: payload },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const getPrintEditPricetagGrid = async (storeNumber: any, offset?: any) => {
  return await clientCall(
    `inventory/pricetag/list/${storeNumber}?limit=20&offset=${offset}`,
    { method: 'GET' },
    InventoryBaseUrl,
    QAToken,
    true
  );
};
export const updatePriceTagStatus = async (payload: any) => {
  // eslint-disable-next-line no-console
  console.log('request received in user for prictagstatus', payload);
  return await clientCall(
    'inventory/recovery/reason',
    { method: 'POST', body: payload },
    InventoryBaseUrl,
    QAToken,
    true
  );
  
};

export const InventoryDetails = async (inventoryNumber: any) => {
  // eslint-disable-next-line no-console
  console.log('invinforeq', inventoryNumber);
  return await clientCall(
   
      `inventory/details/${inventoryNumber}`, 
    { method: 'GET' },
    InventoryBaseUrl,
    QAToken,
    true
  );
  }
