/* eslint-disable prettier/prettier */
import React, { useState, createContext } from 'react';

export const InventoryMajorContext = createContext<any>('');

export function InventoryMajorContextProvider(props: any) {
  const [featureFlagResponse, setfeatureFlagResponse] = useState();
  const [featureFlagDetails, setFeatureFlagDetails] = useState();
  const [searchInvFeature, setsearchInvFeature] = useState();
  const [invInfoFeature, setinvInfoFeature] = useState();
  const [invDetailsFeature, setinvDetailsFeature] = useState();
  const [idleInvAuditFeature, setidleInvAuditFeature] = useState();
  const [invActivitiesFeature, setinvActivitiesFeature] = useState();
  const [agrHistoryFeature, setagrHistoryFeature] = useState();
  const [pricetagFeature, setpricetagFeature] = useState();
  const [printEditPriceTagsFeature, setprintEditPriceTagsFeature] = useState();
  const [transferinvFeature, settransferinvFeature] = useState();
  const [newPackageFeature, setnewPackageFeature] = useState();
  const [inventoryAuditFeature, setInventoryAuditFeature] = useState();
  const [itemBeingServicedFeature, setItemBeingServicedFeature] = useState();
  const [inventoryServicingFeature, setInventoryServicingFeature] = useState();
  const [enablehyperlink, setenablehyperlink] = useState(false);
  const [recoverFeature, setRecoverFeature] = useState();
  const [invDeprConfigResponse, setInvDeprConfigResponse] = useState();
  const [serviceSummaryAry, setserviceSummaryAry] = useState<any>([]);
  const [priceOverrideLimitDetails, setPriceOverrideLimitDetails] = useState({})
  const [termOverrideLimitDetails, setTermOverrideLimitDetails] = useState({})


  return (
    <div>
      <InventoryMajorContext.Provider
        value={{
          featureFlagResponse,
          setfeatureFlagResponse,
          searchInvFeature,
          setsearchInvFeature,
          invInfoFeature,
          setinvInfoFeature,
          invDetailsFeature,
          setinvDetailsFeature,
          idleInvAuditFeature,
          setidleInvAuditFeature,
          invActivitiesFeature,
          setinvActivitiesFeature,
          agrHistoryFeature,
          setagrHistoryFeature,
          pricetagFeature,
          setpricetagFeature,
          printEditPriceTagsFeature,
          setprintEditPriceTagsFeature,
          transferinvFeature,
          settransferinvFeature,
          newPackageFeature,
          setnewPackageFeature,
          inventoryAuditFeature,
          setInventoryAuditFeature,
          itemBeingServicedFeature,
          setItemBeingServicedFeature,
          inventoryServicingFeature,
          setInventoryServicingFeature,
          enablehyperlink,
          setenablehyperlink,
          recoverFeature,
          setRecoverFeature,
          invDeprConfigResponse,
          setInvDeprConfigResponse,
          serviceSummaryAry,
          setserviceSummaryAry,
          priceOverrideLimitDetails,
          setPriceOverrideLimitDetails,
          termOverrideLimitDetails, setTermOverrideLimitDetails,
          featureFlagDetails, setFeatureFlagDetails
        }}
      >
        {props.children}
      </InventoryMajorContext.Provider>
    </div>
  );
}
