/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */
import { RACCOLOR, makeStyles } from "@rentacenter/racstrap";
export const globalStyles = () => {
    const useClasses = makeStyles((theme) => ({

        '@global': {
            '*::-webkit-scrollbar': {
                width: '10px',
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgb(189, 189, 196)',
                borderRadius: '20px',
                border: '3px solid rgb(206, 204, 201)',
            },
            '*::-webkit-scrollbar-track': {
                background: 'rgb(245, 243, 239)',
            },
        },

        /***************************************************************/
        /**** Setting up the default MARGIN values - starts here *****/
        /***************************************************************/
        m0: {
            margin: theme.typography.pxToRem(0),
        },
        m1: {
            margin: theme.typography.pxToRem(4),
        },
        m2: {
            margin: theme.typography.pxToRem(8),
        },
        m3: {
            margin: theme.typography.pxToRem(16),
        },
        m4: {
            margin: theme.typography.pxToRem(24),
        },
        m5: {
            margin: theme.typography.pxToRem(48),
        },
        mAuto: {
            margin: 'auto',
        },

        mr0: {
            marginRight: theme.typography.pxToRem(0),
        },
        mr1: {
            marginRight: theme.typography.pxToRem(4),
        },
        mr2: {
            marginRight: theme.typography.pxToRem(8),
        },
        mr3: {
            marginRight: theme.typography.pxToRem(16),
        },
        mr4: {
            marginRight: theme.typography.pxToRem(24),
        },
        mr5: {
            marginRight: theme.typography.pxToRem(48),
        },
        mrAuto: {
            marginRight: 'auto',
        },

        ml0: {
            marginLeft: theme.typography.pxToRem(0),
        },
        ml1: {
            marginLeft: theme.typography.pxToRem(4),
        },
        ml2: {
            marginLeft: theme.typography.pxToRem(8),
        },
        ml3: {
            marginLeft: theme.typography.pxToRem(16),
        },
        ml4: {
            marginLeft: theme.typography.pxToRem(24),
        },
        ml5: {
            marginLeft: theme.typography.pxToRem(48),
        },
        mlAuto: {
            marginLeft: 'auto',
        },

        mt0: {
            marginTop: theme.typography.pxToRem(0),
        },
        mt1: {
            marginTop: theme.typography.pxToRem(4),
        },
        mt2: {
            marginTop: theme.typography.pxToRem(8),
        },
        mt3: {
            marginTop: theme.typography.pxToRem(16),
        },
        mt4: {
            marginTop: theme.typography.pxToRem(24),
        },
        mt5: {
            marginTop: theme.typography.pxToRem(48),
        },
        mtAuto: {
            marginTop: 'auto',
        },
        me0: {
            marginRight: 0,
          },
      
          me1: {
            marginRight: theme.typography.pxToRem(4),
          },
          me2: {
            marginRight: theme.typography.pxToRem(8),
          },
      
          me3: {
            marginRight: theme.typography.pxToRem(16),
          },
      
          me4: {
            marginRight: theme.typography.pxToRem(24),
          },
      
          me5: {
            marginRight: theme.typography.pxToRem(48),
          },
      
          meAuto: {
            marginRight: 'auto',
          },

        mb0: {
            marginBottom: theme.typography.pxToRem(0),
        },
        mb1: {
            marginBottom: theme.typography.pxToRem(4),
        },
        mb2: {
            marginBottom: theme.typography.pxToRem(8),
        },
        mb3: {
            marginBottom: theme.typography.pxToRem(16),
        },
        mb4: {
            marginBottom: theme.typography.pxToRem(24),
        },
        mb5: {
            marginBottom: theme.typography.pxToRem(48),
        },
        mbAuto: {
            marginBottom: 'auto',
        },
        mbFooterSpacing: {
            marginBottom: theme.typography.pxToRem(98),
        },
        ms0: {
            marginLeft: 0,
          },
      
          ms1: {
            marginLeft: theme.typography.pxToRem(4),
          },
      
          ms2: {
            marginLeft: theme.typography.pxToRem(8),
          },
      
          ms3: {
            marginLeft: theme.typography.pxToRem(16),
          },
      
          ms4: {
            marginLeft: theme.typography.pxToRem(24),
          },
      
          ms5: {
            marginLeft: theme.typography.pxToRem(48),
          },
      
          msAuto: {
            marginLeft: 'auto',
          },
        my0: {
            marginTop: theme.typography.pxToRem(0),
            marginBottom: theme.typography.pxToRem(0),
        },
        my1: {
            marginTop: theme.typography.pxToRem(4),
            marginBottom: theme.typography.pxToRem(4),
        },
        my2: {
            marginTop: theme.typography.pxToRem(8),
            marginBottom: theme.typography.pxToRem(8),
        },
        my3: {
            marginTop: theme.typography.pxToRem(16),
            marginBottom: theme.typography.pxToRem(16),
        },
        my4: {
            marginTop: theme.typography.pxToRem(24),
            marginBottom: theme.typography.pxToRem(24),
        },
        my5: {
            marginTop: theme.typography.pxToRem(48),
            marginBottom: theme.typography.pxToRem(48),
        },
        myAuto: {
            marginTop: 'auto',
            marginBottom: 'auto',
        },

        mx0: {
            marginLeft: theme.typography.pxToRem(0),
            marginRight: theme.typography.pxToRem(0),
        },
        mx: {
            marginLeft: theme.typography.pxToRem(4),
            //marginRight: theme.typography.pxToRem(4),
        },
        mx1: {
            marginLeft: theme.typography.pxToRem(4),
            marginRight: theme.typography.pxToRem(4),
        },
        mx2: {
            marginLeft: theme.typography.pxToRem(8),
            marginRight: theme.typography.pxToRem(8),
        },
        mx3: {
            marginLeft: theme.typography.pxToRem(16),
            marginRight: theme.typography.pxToRem(16),
        },
        mx4: {
            marginLeft: theme.typography.pxToRem(24),
            marginRight: theme.typography.pxToRem(24),
        },
        mx5: {
            marginLeft: theme.typography.pxToRem(48),
            marginRight: theme.typography.pxToRem(48),
        },
        mxAuto: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        price1: {
            paddingleft: "68%",
        },
        /***************************************************************/
        /**** Setting up the default MARGIN values - ends here *****/
        /***************************************************************/


        /***************************************************************/
        /**** Setting up the default PADDING values - starts here *****/
        /***************************************************************/
        p0: {
            padding: '0 !important',
        },
        p1: {
            padding: theme.typography.pxToRem(4),
        },
        p2: {
            padding: theme.typography.pxToRem(8),
        },
        p3: {
            padding: theme.typography.pxToRem(16),
        },
        p4: {
            padding: theme.typography.pxToRem(24),
        },
        p5: {
            padding: theme.typography.pxToRem(48),
        },
        pAuto: {
            padding: 'auto',
        },
        modalBody1: {
            marginTop: '7%'
        },

        pr0: {
            paddingRight: theme.typography.pxToRem(0),
        },
        pr1: {
            paddingRight: theme.typography.pxToRem(4),
        },
        pr2: {
            paddingRight: theme.typography.pxToRem(8),
        },
        pr3: {
            paddingRight: theme.typography.pxToRem(16),
        },
        pr4: {
            paddingRight: theme.typography.pxToRem(24),
        },
        pr5: {
            paddingRight: theme.typography.pxToRem(48),
        },
        prAuto: {
            paddingRight: 'auto',
        },

        pl0: {
            paddingLeft: theme.typography.pxToRem(0),
        },
        pl1: {
            paddingLeft: theme.typography.pxToRem(4),
        },
        pl2: {
            paddingLeft: theme.typography.pxToRem(8),
        },
        pl3: {
            paddingLeft: theme.typography.pxToRem(16),
        },
        pl4: {
            paddingLeft: theme.typography.pxToRem(24),
        },
        pl5: {
            paddingLeft: theme.typography.pxToRem(48),
        },
        plAuto: {
            paddingLeft: 'auto',
        },

        pt0: {
            paddingTop: theme.typography.pxToRem(0),
        },
        pt1: {
            paddingTop: theme.typography.pxToRem(4),
        },
        pt2: {
            paddingTop: theme.typography.pxToRem(8),
        },
        pt3: {
            paddingTop: theme.typography.pxToRem(16),
        },
        pt4: {
            paddingTop: theme.typography.pxToRem(24),
        },
        pt5: {
            paddingTop: theme.typography.pxToRem(48),
        },
        ptAuto: {
            paddingTop: 'auto',
        },

        pb0: {
            paddingBottom: 'theme.typography.pxToRem(0)',
        },
        pb1: {
            paddingBottom: theme.typography.pxToRem(4),
        },
        pb2: {
            paddingBottom: theme.typography.pxToRem(8),
        },
        pb3: {
            paddingBottom: theme.typography.pxToRem(16),
        },
        pb4: {
            paddingBottom: theme.typography.pxToRem(24),
        },
        pb5: {
            paddingBottom: theme.typography.pxToRem(48),
        },
        pbAuto: {
            paddingBottom: 'auto',
        },
                popupText: {
            fontFamily: 'OpenSans-semibold',
            fontSize: '17px',
          },

        py0: {
            paddingTop: theme.typography.pxToRem(0),
            paddingBottom: theme.typography.pxToRem(0),
        },
        py1: {
            paddingTop: theme.typography.pxToRem(4),
            paddingBottom: theme.typography.pxToRem(4),
        },
        py2: {
            paddingTop: theme.typography.pxToRem(8),
            paddingBottom: theme.typography.pxToRem(8),
        },
        py3: {
            paddingTop: theme.typography.pxToRem(16),
            paddingBottom: theme.typography.pxToRem(16),
        },
        py4: {
            paddingTop: theme.typography.pxToRem(24),
            paddingBottom: theme.typography.pxToRem(24),
        },
        py5: {
            paddingTop: theme.typography.pxToRem(48),
            paddingBottom: theme.typography.pxToRem(48),
        },
        pyAuto: {
            paddingTop: 'auto',
            paddingBottom: 'auto',
        },

        px0: {
            paddingLeft: theme.typography.pxToRem(0),
            paddingRight: theme.typography.pxToRem(0),
        },
        px1: {
            paddingLeft: theme.typography.pxToRem(4),
            paddingRight: theme.typography.pxToRem(4),
        },
        px2: {
            paddingLeft: theme.typography.pxToRem(8),
            paddingRight: theme.typography.pxToRem(8),
        },
        px3: {
            paddingLeft: theme.typography.pxToRem(16),
            paddingRight: theme.typography.pxToRem(16),
        },
        px4: {
            paddingLeft: theme.typography.pxToRem(24),
            paddingRight: theme.typography.pxToRem(24),
        },
        px5: {
            paddingLeft: theme.typography.pxToRem(48),
            paddingRight: theme.typography.pxToRem(48),
        },
        pxAuto: {
            paddingLeft: 'auto',
            paddingRight: 'auto',
        },

        pbCardContentCustom: {
            paddingBottom: '0.5rem !important',
        },
        /***************************************************************/
        /**** Setting up the default PADDING values - ends here *****/
        /***************************************************************/



        /***************************************************************/
        /**** Setting up the default FONT values - starts here *****/
        /***************************************************************/
        fs1: {
            fontSize: theme.typography.pxToRem(40),
        },
        fs2: {
            fontSize: theme.typography.pxToRem(32),
        },
        fs3: {
            fontSize: theme.typography.pxToRem(28),
        },
        fs4: {
            fontSize: theme.typography.pxToRem(24),
        },
        fs5: {
            fontSize: theme.typography.pxToRem(20),
        },
        fs6: {
            fontSize: theme.typography.pxToRem(16),
        },
        fs7: {
            fontSize: theme.typography.pxToRem(14),
        },
        fontSemiBold: {
            fontFamily: 'OpenSans-semibold',
        },
        fontBold: {
            fontFamily: 'OpenSans-bold',
        },
        font18:{
            fontSize: theme.typography.pxToRem(18),
        },
        /***************************************************************/
        /**** Setting up the default FONT values - ends here *****/
        /***************************************************************/


        /***************************************************************/
        /**** Setting up the BACKGROUND values - starts here *****/
        /***************************************************************/
        bgPrimary: {
            backgroundColor: '#2179FE',
        },
        bgTransparent: {
            backgroundColor: 'transparent',
        },
        bgLightBlue: {
            backgroundColor: '#EFF4FF',
        },
        bgLightGrey: {
            backgroundColor: '#fafafa',
        },
        bgRed: {
            backgroundColor: '#e85c5c',
        },
        bgPaleYellow: {
            backgroundColor: '#d58b00',
        },
        /***************************************************************/
        /**** Setting up the BACKGROUND values - ends here *****/
        /***************************************************************/


        /***************************************************************/
        /**** Setting up the COLOR values - starts here *****/
        /***************************************************************/
        textGrey: {
            color: '#4A5174',
        },
        textBlack: {
            color: '#000000',
        },
        textWhite: {
            color: '#ffffff',
        },
        textBlue: {
            color: '#2179FE',
        },
        textBlue1: {
            color: '#2279fd'
        },
        textViolet: {
            color: '#2e31be',
        },
        textDisabled: {
            color: '#818181',
        },
        racBlue: {
            backgroundColor: `${RACCOLOR.NORMAL_BLUE}`,
        },
        racSkyBlue: {
            backgroundColor: '#EFF4FF'
        },
        /***************************************************************/
        /**** Setting up the COLOR values - ends here *****/
        /***************************************************************/


        /***************************************************************/
        /**** Setting up the TEXT TREATMNET values - starts here *****/
        /***************************************************************/
        textIndent: {
            textIndent: '-9999px'
        },
        justifySpaceBetween: {
            justifyContent: 'space-between',
        },
        textCenter: {
            textAlign: 'center',
        },
        textRight: {
            textAlign: 'right',
        },
        textLeft:{
            textAlign:'left',
        },
        /***************************************************************/
        /**** Setting up the TEXT TREATMNET values - ends here *****/
        /***************************************************************/


        /***************************************************************/
        /**** Setting up the BORDER and RADIUS values - starts here *****/
        /***************************************************************/
        borderBottom: {
            borderBottom: '1px solid #d9d9d9',
        },
        borderLeftViolet: {
            borderLeft: '3px solid #2d31be',
        },
        borderRadius0: {
            borderRadius: '0 !important',
        },
        borderRadius5: {
            borderRadius: theme.typography.pxToRem(5),
        },
        borderRadius10: {
            borderRadius: theme.typography.pxToRem(10),
        },
        borderRadius50: {
            borderRadius: theme.typography.pxToRem(50),
        },
        borderNone: {
            border: theme.typography.pxToRem(0),
        },
        /***************************************************************/
        /***** Setting up the BORDER and RADIUS values - ends here *****/
        /***************************************************************/


        /***************************************************************/
        /**** Setting up the COMPONENT ALIGNMENT - starts here *****/
        /***************************************************************/
        componentLeft: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            display: 'flex',
        },
        breadcrumb: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            padding: 0,
            marginBottom: theme.typography.pxToRem(10),
            listStyle: 'none',
          },
          slotBooked: {
            border: '1px solid #2179fe',
            backgroundColor: '#2179fe',
            color: '#ffffff',
            paddingRight: '11px',
            paddingBottom: '17px',
            paddingTop: '17px',
            paddingLeft: '13px',
            fontSize: '14px',
            marginBottom: '10px',
            fontFamily: 'OpenSans-semibold',
          },
          agrTimeSlot: {
            width: '20%',
            display: 'inline-block',
          },
          semiBold: {
            fontFamily: 'OpenSans-semibold',
          },
          slotBusy: {
            backgroundColor: '#eaeef1',
            color: ' #767676',
            paddingRight: '11px',
            paddingBottom: '17px',
            paddingTop: '17px',
            paddingLeft: '13px',
            fontSize: '14px',
            marginBottom: '10px',
            fontFamily: 'OpenSans-semibold',
            border: '0px solid',
          },
          slotAvailable: {
            border: '1px solid #2179fe',
            color: '#2179fe',
            borderImage: 'initial',
            backgroundColor: 'hsl(0deg 0% 100%)',
            paddingRight: '11px',
            paddingBottom: '17px',
            paddingTop: '17px',
            paddingLeft: '13px',
            fontSize: '14px',
            marginBottom: '10px',
            fontFamily: 'OpenSans-semibold',
          },
          Norecords: {
            textAlign: 'center',
            marginTop: theme.typography.pxToRem(30),
            marginBottom: theme.typography.pxToRem(30),
            fontSize: theme.typography.pxToRem(16),
            color: `${RACCOLOR.RICH_BLACK}`,
            width: '100%',
          },
          breadcrumbItemActive: {
            width: 'auto',
            fontFamily: 'OpenSans-semibold',
            fontSize: '12px',
            color: ' #8E8E8E',
            paddingLeft: '15px',
            paddingRight: 0,
          },
          racpadLinkCustomer: {
            fontFamily: 'OpenSans-bold',
            fontSize: '14px',
            color: '#2179FE',
            textDecoration: 'none',
          },
          breadCrumbMarg: {
            marginLeft: '20px',
          },
          breadcrumbItem: {
            width: 'auto',
            fontFamily: 'OpenSans-semibold',
            fontSize: '12px',
          },
          breadcrumbArrow: {
            '&:before': {
              content: '""',
              width: 0,
              height: 0,
              borderTop: '5px solid transparent',
              borderLeft: '7px solid #707070',
              borderBottom: '5px solid transparent',
              marginTop: 0,
              float: 'left',
              paddingRight: '13px',
              color: '#6c757d',
              paddingLeft: 0,
            },
          },
        componentCenter: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
        },
        componentRight: {
            justifyContent: 'flex-end',
            alignItems: 'center',
            display: 'flex',
        },
        componentSpaceBetween: {
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
        },
        alignCenter: {
            alignItems: 'center',
        },
        dflex: {
            display: 'flex',
        },
        /***************************************************************/
        /**** Setting up the COMPONENT ALIGNMENT - starts here *****/
        /***************************************************************/

        shadow0: {
            boxShadow: 'none',
        },

        w100: {
            width: '100%',
        },
        w95: {
            width: '95%',
        },
        widthAuto: {
            width: 'auto',
        },

        positionRelative: {
            position: 'relative',
        },
        racCard: {
            borderRadius: theme.typography.pxToRem(10),
            boxShadow: '0 0 3px #d5d4d4',
        },

        underlinetxtDecoration:{
            textDecoration:'underline !important'
        },
        /**** Need to revisit the CSS below ****/
        denominationBtn: {
            paddingTop: theme.typography.pxToRem(4),
            paddingBottom: theme.typography.pxToRem(4),
            paddingLeft: theme.typography.pxToRem(24),
            paddingRight: theme.typography.pxToRem(24),
            border: '1px solid #2468ff',
            fontFamily: 'Opensans-bold',
            minWidth: '100px',
        },
        denominationTextBox: {
            width: '115px',
        },
        fixedFooter: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.typography.pxToRem(16),
            backgroundColor: '#ffffff',
            boxShadow: '0 0 3px #d9d9d9',
            marginTop:'1%'
        }, 
        filterPositionAlign: {
            marginBottom: "20% !important",
            marginLeft: "70% !important"
        },

        racGlobalSearchBtn: {
            cursor: 'pointer',
            width: '25px',
            float: 'left',
            paddingTop: '0.2rem',
        },

        customPageSize: {
            width: '400px',
        },
        borderRadiusPosition: {

            '& div': {

                '& div': {

                    borderBottomLeftRadius: "18px !important",
                    borderBottomRightRadius: "18px !important",
                    borderTopLeftRadius: "18px !important",
                    borderTopRightRadius: "0px !important"

                }

            }

        },
        //lokesh

        formLabel: {
            marginBottom: "0.2rem",
            color: "#111111",
            fontFamily: "OpenSans-semibold",
            fontSize: theme.typography.pxToRem(14),
        },
        modalTitle: {
            marginBottom: '0',
            lineHeight: '1.5',
        },
        modalBody: {
            position: 'relative',
            flex: '1 1 auto',
            padding: '1rem'
                // marginTop: '34%'
        },
        racCol12: {
            flex: '0 0 auto',
            width: '100%',
        },
        textRed: {
            color: `${RACCOLOR.MVS_RED}`,
        },
        tabPane: {
            width: '98.5%',
        },
        row: {
            display: "flex",
            flexWrap: "wrap",
            marginTop: theme.typography.pxToRem(14),
            marginRight: theme.typography.pxToRem(14),
            marginLeft: theme.typography.pxToRem(14),
        },
        card: {
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
            width: "100%",
        },
        paddingbadge: {
            paddingLeft: '5px',
          },
        label: {
            fontFamily: 'OpenSans-semibold',
          },
        problemtext: {
            width: '100%',
            paddingBottom: '9% ',
            marginTop: '10px',
            border: '1px solid #ced4da',
            borderTopLeftRadius: '0.25rem',
            borderBottomLeftRadius: '0.25rem',
            borderTopRightRadius: '0.25rem',
            borderBottomRightRadius: '0.25rem',
          },
          dollartext: {
            width: '70%',
            '& input': {
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                padding:'7px',
                textAlign:'right'

            },
          },
          amounttext:{
            padding:'7px 14px'
          },
        racCol6: {

            flex: '0 0 auto',

            width: '50%',

        },

        racCol10: {

            flex: '0 0 auto',

            width: '83.3333333333%',

        },

        racCol2: {

            flex: '0 0 auto',

            width: '16.666666666%',

        },

        racCol8: {

            flex: '0 0 auto',

            width: '66.6666666667%',

        },
        racCol4: {
            flex: '0 0 auto',
            width: '33.3333333333%',
        },
        floatLeft: {
            float: "left",
        },
        colGap: {
            marginLeft: '3px'
        },
        formLabelValue: {
            fontFamily: 'OpenSans-semibold',
            fontSize: '14px',
            color: '#4A5174',
        },
        w50: {
            width: "50%",
        },
        w25: {
            width: "25%"
        },
        w75: {
            width: "75%",
        },
        colHeight: {
            marginTop: '3px'
        },
        textArea: {
            display: "block",
            width: "100%",
            padding: "0.375rem 0.75rem",
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5,
            color: "#212529",
            backgroundColor: `${RACCOLOR.WHITE}`,
            border: "1px solid #ced4da",
            appearance: "none",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: "0.25rem",
            borderBottomRightRadius: "0.25rem"
        },
        ps4: {
            paddingLeft: theme.typography.pxToRem(24),
        },
        floatRight: {
            float: "right",
        },
        racpadSubheader: {
            fontSize: '18px',
            fontFamily: 'OpenSans-bold',
        },
        racpadLink: {
            color: `${RACCOLOR.DEEP_SKY_BLUE}`,
            fontSize: theme.typography.pxToRem(14),
            textDecoration: "none",
            cursor: "pointer",
            fontFamily: "OpenSans-bold",
        },
        flexFill: {
            flex: '1 1 auto',
        },
        fixedBottom: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.typography.pxToRem(16),
            backgroundColor: '#ffffff',
            boxShadow: '0 0 3px #d9d9d9',
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1030,
        },
        fixedBottom2: {
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1030,
            backgroundColor: `${RACCOLOR.WHITE}`,
            boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
            padding: '1rem',
        },
        barcodeheight:{
            '& svg':{
              width:'260px !important'
            }
        },
        bgWhite: {
            backgroundColor: `${RACCOLOR.WHITE}`,
        },
        shadow: {
            boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
        },
        loaderStyle: {
            border: 'none',
            backgroundColor: 'transparent',
            outline: 'none',
            textAlign: 'center',
        },
        racLoaderPage: {
            textAlign: 'center',
            marginTop: '250px',
            fontSize: '16px',
            marginLeft: '30px',
            marginRight: '30px',
            outline: 'none',
        },
        hide: {
            display: 'none',
        },
        hiddenRow: {
            backgroundColor: 'white',
        },
        popUpBtnWidth: {
            width: '22%'
        },
        RACPOPMsg: {
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(17),
            letterSpacing: 0,
        },
        breakPackageModalWidth: {
            '& div': {
                '& div': {
                    maxWidth: "450px !important",
                    maxHeight: "500px !important",
                }
            }
        },
        GridLoader: {
            textAlign: "center",
            marginBottom: theme.typography.pxToRem(20),
            fontSize: theme.typography.pxToRem(16),
            color: `${RACCOLOR.RICH_BLACK}`,
            margin: "30px 0px",
        },
        racpadBadge: {
            borderRadius: '20px',
        },
        bgInfo: {
            backgroundColor: '#0dcaf0',
        },
        bgWarning: {
            backgroundColor: '#ffc107',
        },
        bgAsh: {
            backgroundColor: '#c8d1d3',
        },
        bgSuccess: {
            backgroundColor: '#198754',
        },
        badge: {
            display: 'inline-block',
            marginTop: '10px',
            padding: '5px 10px',
            fontSize: '0.75em',
            fontWeight: 700,
            lineHeight: 1,
            color: '#fff',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            verticalAlign: 'baseline',
            borderRadius: '20px',
        },
        racpadContainer: {
            marginTop: '63px',
        },
        racpadTab: {
            display: 'flex',
        },
        navLinkItem: {
            borderBottom: 'transparent',
            fontSize: '14px',
            fontFamily: 'OpenSans-semibold',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
        },
        navLinkActive: {
            color: '#2279fd',
            borderBottom: '3px solid #2279fd',
        },
        modalWidth: {
            '& div': {
                '& div': {
                    maxWidth: "330px !important",
                    maxHeight: "390px !important"
                }
            }
        },
        saveModalWidth: {
            '& div': {
                '& div': {
                    maxWidth: "380px !important",
                }
            }
        },
        chargeOffModalWidth: {
            '& div': {
                '& div': {
                    maxWidth: "380px !important",
                    maxHeight: "210px !important"
                }
            }
        },
        chargeOffModalWidth1: {
            '& div': {
                '& div': {
                    maxWidth: "340px !important",
                    maxHeight: "190px !important"
                }
            }
        },
        chargeOffModalWidth2: {
            '& div': {
                '& div': {
                    maxWidth: "400px !important",
                    maxHeight: "180px !important"
                }
            }
        },
        px6: {
            paddingRight: theme.typography.pxToRem(45),
            paddingLeft: theme.typography.pxToRem(45),
        },
        agrPrintDoc: {
            color: '#2179fe',
        },
        ahActive: {
            borderColor: '#5AE286 !important',
        },

        ahInactive: {
            borderColor: '#BBC1CE !important',
        },

        ahDue: {
            borderColor: '#FD6A63 !important',
        },
        ahPending:{
            borderColor:'yellow !important'
        },
        racpadAgrLink: {
            borderLeft: '3px solid transparent',
            paddingLeft: '10px',
            borderRadius: '2px'
        },
        title: {
            color: '#212529',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(16),
            marginBottom: theme.typography.pxToRem(10),
        },
        subTitle: {
            color: '#212529',
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(14),
            marginBottom: theme.typography.pxToRem(10),
        },
        headerMainDiv: {
            paddingLeft: '7px',
            paddingRight: '8px',
        },
        headerMainCard: {
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
        },
        pb12: {
            paddingBottom: '12px',
        },
        mainInvCard: {
            marginLeft: '6.5px',
            marginRight: '8px',
            borderBottomRightRadius: '0px',
            borderBottomLeftRadius: '0px',
        },
        mainInvCardContent: {
            paddingTop: '4px !important',
            paddingBottom: '14px !important'
        },
        textAreaStyle: {
            marginTop: '4px',
            borderRadius: '7px',
            fontSize: '1pc',
            padding: '12px',
            fontFamily: 'initial'
        },
        pt9: {
            paddingTop: '9px',
        },
        pt14: {
            paddingTop: '14px',
        },
        pt16: {
            paddingTop: '16px'
        },
        pb9: {
            paddingBottom: '9px',
        },
        pb10: {
            paddingBottom: '10px',
        },
        ml6: {
            marginLeft: '6px',
        },
        mt7: {
            marginTop: '7px',
        },
        mt20: {
            marginTop: '20px',
        },
        mt57: {
            marginTop: '57px',
        },
        mt110: {
            marginTop: '110px',
        },
        mr45: {
            marginRight: '45px',
        },
        mb10: {
            marginBottom: '10px',
        },
        mb12: {
            marginBottom: '12px',
        },
        mb20: {
            marginBottom: '20px',
        },
        popUpMainDiv: {
            paddingTop: '0px',
            paddingBottom: '0px',
            marginTop: '-14px'
        },
        bgdateError: {
            '& input': {
              border: 'thin solid red',
            },
          },
        mtm13: {
            marginTop: '-13px',
        },
        mtm28: {
            marginTop: '-28px',
        },
        mtm20: {
            marginTop: '-20px',
        },
        mbm10: {
            marginBottom: '-10px',
        },
        //
        ml45: {
            marginLeft: '45%',
        },
        mtm4: {
            marginTop: '-4px',
        },
        mtm5: {
            marginTop: '-5px',
        },
        mtm9: {
            marginTop: '-9px',
        },
        mb100: {
            marginBottom: '100px',
        },
        fiveColumns: {
            ['@media (min-width:1366px)']: {
                maxWidth: '20%!important',
                flexBasis: '20%',
            },
        },
         fourColumns: {
            ['@media (min-width:1366px)']: {
                maxWidth: '25%!important',
                flexBasis: '25%',
            },
        },
        borderRadius4px: {
            borderRadius: '4px'
        },
        textMildGrey: {
            color: '#6c757d',
        },
        statusBadge: {
            padding: '1px 8px',
            marginLeft: '8px',
            color: 'white',
            borderRadius: '10px',
            fontSize: '12px'
        },
        statusGreen: {
            backgroundColor: '#03c03c'
        },
        statusWarning: {
            backgroundColor: '#d68b00'
        },
        statusInfo: {
            backgroundColor: '#0bbab6'
        },
        width10: {
            width: '50px'
        },
        headerWidth: {
            marginLeft: '-15px',
            marginRight: '-12px'
        },
        //added by krishnaja for actionbutton
        actionBtn: {
            color: "#2179FE",
            borderRadius: theme.typography.pxToRem(5),
            backgroundColor: 'rgb(239,244,255)',
            fontSize: theme.typography.pxToRem(12),
        },
        // added by charitra
        cursorDefault: {
            cursor: "default"
        },
        masterLoader: {
            position: 'fixed',
            backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 1050,
            textAlign: 'center',
            margin: 0,
        },
        Loader: {
            display: 'block',
            position: 'fixed',
            zIndex: 1051,
            top: '50%',
            right: '50%',
        },
        //added by krishnaja for priceTagPopUP
        marginLeftRight40: {
            marginLeft: '40%',
            marginRight: '40%'
        },
        testCss: {
            borderRadius: theme.typography.pxToRem(4),
            width: "150px",
            border: '0.5px solid grey',
            fontFamily: 'OpenSans-semibold',
            padding: theme.typography.pxToRem(6),
            textAlignLast: 'right',
            borderColor: '#c8d1d3',
            "&:focus": {
                borderColor: '0.5px solid red',
            }
        },
        mr6: {
            marginRight: theme.typography.pxToRem(50),
        },
        inputBox: {
            borderRadius: theme.typography.pxToRem(16),
            width: "180px",
            padding: "0.375rem 0.75rem",

            '& input': {
                height: '22px',
                fontSize: '16px',
                fontWeight: 400,
            }

        },
        textRightAlign: {
            textAlignLast: 'right'
        },
        btnWidth: {
            width: '27%'
        },
        width40: {
            width: '40%'
        },
        priceTagPageLoadModalWidth: {
            '& div': {
                '& div': {
                    maxWidth: "600px !important",
                    maxHeight: "190px !important",
                }
            }
        },
        priceTagPrintModalWidth: {
            '& div': {
                '& div': {
                    maxWidth: "350px !important",
                    maxHeight: "500px !important",
                }
            }
        },
        priceExceptionModalWidth: {
            '& div': {
                '& div': {
                    maxWidth: "600px !important",
                    maxHeight: "800px !important",
                }
            },
        },
        formControl: {
            display: "block",
            width: "100%",
            padding: "0.375rem 0.75rem",
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5,
            color: "#212529",
            backgroundColor: `${RACCOLOR.WHITE}`,
            border: "1px solid #ced4da",
            appearance: "none",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: "0.25rem",
            borderBottomRightRadius: "0.25rem",
            textAlign: "right"
        },

        racLoader: {
            textAlign: 'center',
            marginTop: '328px',
            fontSize: '16px',
            marginLeft: '30px',
            marginRight: '30px',
            outline: 'none',
        },
        listTabPadding: {
            padding: '15px 20px 12px 20px !important'
        },
        headerCardPadding: {
            padding: '0px 0px 0px 6px !important',
            marginLeft: '14px !important'
        },
        listPadding: {
            padding: '6px 0px 0px 0px !important'
        },
        w80: {
            width: '80%',
        },
        agreementContainerSpacing: {
            paddingLeft: '10px',
            paddingRight: '10px',
            marginBottom: '120px',
        },
        // added by kalai

        justifyContentCenter: {
            alignItems: "center",
            marginTop: "10%",
            justifyContent: "center",
        },
        dynamicCssForSelect: {
            '& div': {
                borderBottomLeftRadius: "18px !important",
                borderBottomRightRadius: "18px !important",
                borderTopLeftRadius: "18px !important",
                borderTopRightRadius: "18px !important"
            }
        },

        //for inventoryTrasfer Grid added by Krishnaja
        bgTransparentRed:{
            backgroundColor:'rgba(255,0,0,0.4)',
            color: '#ffffff',
            "&:hover": {
                cursor: 'default',
                backgroundColor:'rgba(255,0,0,0.4)',
            },
        },
        onBtnHover:{
            "&:hover": {
                backgroundColor:'#e85c5c',
            }
        },
        selectBox:{
            '& div':{
                padding:'10px 9px'            }
        },
        fs8: {
            fontSize: theme.typography.pxToRem(13),
        },
        makeBold:{
            fontWeight:"bolder"
        },
        px7: {
            paddingLeft: theme.typography.pxToRem(35),
            paddingRight: theme.typography.pxToRem(35),
        },
        SelectLanguageModalWidthmax: {
            '& div': {
                '& div': {
                    maxWidth: "700px !important",
                    maxHeight: "800px !important",
                },
            },
        },
        SelectLanguageModalWidth: {
            '& div': {
                '& div': {
                    '& h5':{
                        textAlign:'center !important',
                       }
                },
            },
        },
        
        px8: {
            paddingLeft: theme.typography.pxToRem(30),
            paddingRight: theme.typography.pxToRem(30),
        },
        margincard:{
            margin:"18px 10px",
            fontSize:theme.typography.pxToRem(14),
        },
        bluecards:{
            boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
            width: "100%",

        },
        priceTagLabelColour: {
            fontFamily: 'OpenSans-bold',
            color: `${RACCOLOR.GRAY}`,
            fontSize: '14px',
          },
        margincardsize: {
            marginTop: '10px',
        },
        mxe: {
            marginLeft: theme.typography.pxToRem(4),
      
            marginRight: theme.typography.pxToRem(36),
          },
          formCheck: {
            minHeight: '1.5rem',
            marginBottom: '0.125rem',
            display: 'inline-block',
            marginRight: '1rem',
            paddingLeft: 0,
          },
          spacerMB5: {
            marginBottom: theme.typography.pxToRem(24),
          },
          spacerMT8: {
            marginTop: theme.typography.pxToRem(20),
          },
          font14: {
            fontSize: theme.typography.pxToRem(14),
          },
          MarginDate: {
            marginBottom: '5px',
          },
          agrListGroup: {
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '10px',
            marginBottom: 0,
            borderRadius: '0.25rem',
          },
          deliveryEventDesc: {
            fontSize: '13px',
            fontFamily: 'OpenSans-regular',
            color: '#525252',
          },
          me48: {
            marginRight: theme.typography.pxToRem(48),
          },
          circleAvailable: {
            width: '10px',
            height: '10px',
            float: 'left',
            borderRadius: '20px',
            marginTop: '4px',
            marginRight: '7px',
            background: '#2468FF',
          },
          spacerMR4: {
            marginRight: theme.typography.pxToRem(12),
          },
          circleBusy: {
            width: '10px',
            height: '10px',
            float: 'left',
            borderRadius: '20px',
            marginTop: '4px',
            marginRight: '7px',
            background: '#D3D4D5',
          },
          cardidle: {
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            marginLeft: '6px',
            marginRight: '6px',
            padding: '0%',
          },
          commentText: {
            '& input: focus': {
              border: '1px solid #ced4da',
            },
            width: '100%',
            paddingBottom: '9% ',
            marginTop: '10px',
            border: '1px solid #ced4da',
            borderTopLeftRadius: '0.25rem',
            borderBottomLeftRadius: '0.25rem',
            borderTopRightRadius: '0.25rem',
            borderBottomRightRadius: '0.25rem',
          },
          racErrorFunction: {
            fontFamily: 'OpenSans-semibold',
            marginBottom: '20px',
            marginTop: '10px',
          },
          racErrorIcon: {
            width: '50px',
          },
          spacerME2: {
            marginRight: theme.typography.pxToRem(16),
          },
    }));


    const classes = useClasses();
    return classes;
};