/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect, useContext, useReducer, useRef, useLayoutEffect } from "react";
import { printHTML } from '../../Shared/print'
import NumberFormat from "react-number-format";
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { InventoryContext } from '../../../context/InventoryInfoContext';
import moment from "moment";
import { truncString, validateObject } from "../../Shared/validateObject";
import SecondFactor from '../InventoryInfo/secondAuthen';
import { ReactComponent as SuccessIcon } from '../../../assets/images/success-icon.svg'
import { Grid, RACTextbox, RACButton, RACDatePicker, RACModalCard, Card, CardContent, Typography, RACSelect, CircularProgress } from '@rentacenter/racstrap';
import { LoadInventoryInformation, InventoryDetails, saveInventoryInfo, getCoworkerDetails, updateInventoryStatus, getRadiusStore, updateTransfer, getInventoryStatusReasons, getCurrentUser } from '../../../api/user';
import { globalStyles } from "../../componentstyles/globalstyles"
import { formStyles } from "../../componentstyles/formstyles";
import { ON_RENT, RENT_READY, SERVICE_ON_RENT, LOANER, NOT_RENT_READY, PENDING_CHARGE_OFF, CHARGED_OFF, HOLD, SERVICE_IDLE, RESERVED, TRANSFER_IN_PROGRESS, PENDING_RENTAL_AGREEMENT, PENDING_LOANER_AGREEMENT, Inventory_Status_Reasons, ADD_ON_PRICING } from "../../../constants/constants";
import { ReactComponent as PDFImages } from '../../../assets/images/pdf-icon.svg';
import { useParams } from 'react-router-dom';
import PrintInfo from '../printInfo'
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import {
  InventoryMajorContext,
} from '../../../context/inventoryMajorContext';
// import ReactToPrint from 'react-to-print';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Barcode = require('react-barcode');
interface ParamType {
  inventoryNum: string;
}

export const Inventoryinfo = (props: any) => {
  const {
    transferinvFeature,
    recoverFeature,
    featureFlagDetails
  } = useContext(InventoryMajorContext);
  console.log("recoverFeatureResponse", recoverFeature);
  let coWorkerRole = '';
  const storeNo: any = sessionStorage.getItem('storeNumber');
  const classes = globalStyles();
  const form = formStyles();
  const history = useHistory();
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  // const componentRef = useRef(null);
  const { inventoryNum } = useParams<ParamType>();
  const NOT_RECOVER_CHARGEOFF_REASON_REFCODES = ["5", "1", "2", "INVREV", "EP", "SAC"];
  const [popUpStateForAll, setpopUpStateForAll] = useState({
    popup: false,
    transferSuccessPopup: false,
    recoverpopup: false,
    chargeoffpopup: false,
    chargeoffConfirmpopup: false,
    chargeoffCancelpopup: false,
    loanerpopup: false,
    savePopupState: false,
    transferPrintPopup: false,
    somethingWentWrongPopUpState: false
  })
  const [inventoryInformations, setinventoryInformations] = useState<any>(
    {
      inventoryHeaderObj: {},
      inventoryInformationObj: {},
      currentAgreementObj: {},
      chargedOffObj: {},
      recoveryObj: {},
      packageObj: {},
    }
  )
  const [inventoryDetails, setinventoryDetails] = useState<any>(
    {
      inventoryHeaderObj: {},
      depreciationEstimatorObj: {},
      gaapDepreciationObj: {},
      idleObj: {}
    }
  )
  const [secondLvlAuth, setsecondLvlAuth] = useState(false);
  const [savebtnLoader, setsavebtnLoader] = useState(false);
  const [savebtnTransfer, setsavebtnTransfer] = useState(false);
  const [auditMessage, setauditMessage] = useState('');
  const [serialNumber, setserialNumber] = useState('');
  const { headerValue, tabDisableContext, chargeOffRecoveryContextVal } = useContext(InventoryContext);
  const [headerContext, setheaderContext] = headerValue;
  const [tabDisable, settabDisable] = tabDisableContext;
  const [chargeOffRecoveryContext, setchargeOffRecoveryContext] = chargeOffRecoveryContextVal;
  const [transferValidatePack, setTransferValidatePack] = useState(false);
  const search = useLocation().search;
  const saleslead = new URLSearchParams(search).get('salesLeadId');
  const [coWorkerRoleForAll, setcoWorkerRoleForAll] = useState({
    coWorkerRoleForSN: false,
    coWorkerRoleForCF: false,
    coWorkerRoleForH: false,
    coWorkerRoleForRSVD: false,
    coWorkerRoleForR: false
  })
  const [currentCWRole, setcurrentCWRole] = useState('');
  const [employeeId, setemployeeId] = useState('');
  const [authenType, setauthenType] = useState<'RR' | 'NRR' | 'PCO' | 'CO' | 'CCO' | 'H' | 'UH' | 'UR' | 'R' | 'T' | 'SOR' | ''>('');
  const [inventoryChargeReason, setinventoryChargeReason] = useState<any>([]);
  const [inventoryRecoveryReason, setinventoryRecoveryReason] = useState<any>([]);
  const [chargeOffState, setchargeOffState] = useState('Select');
  const [recoverReasonState, setrecoverReasonState] = useState('Select');
  const [transferState, settransferState] = useState('Select');
  const [radiusStore, setradiusStore] = useState<any>();
  const [radiusStoreLoader, setradiusStoreLoader] = useState(false);
  const [ddDisable, setddDisable] = useState(false);
  const [txtDisable, settxtDisable] = useState(false);
  const [transfer2Store, settransfer2Store] = useState('');
  const [btnVisible, setbtnVisible] = useState(true);
  const [showErrMsg, setshowErrMsg] = useState(false);
  const [reFreshLoader, setreFreshLoader] = useState(true);
  const [responseLoader, setresponseLoader] = useState({
    loader1: false,
    loader2: true
  });
  const [somethingWentWrong, setsomethingWentWrong]: any = React.useState(false);
  const [enableSaveBtnForAuditMsg, setenableSaveBtnForAuditMsg] = useState(false);
  const [moduleName, setmoduleName] = useState('');
  const [errMsgTxt, seterrMsgTxt] = useState('');
  const [Validredirect, setValidredirect] = useState<boolean>(false);
  const [infoPrint, setInfoPrint] = useState(true);
  const [printFailed, setPrintFailed] = useState(false);
  const [printFailOpen, setPrintFailOpen] = useState(false);
  const [receivingStore, setreceivingStore] = useState('');
  const [recoverSecondLvlAuth, setRecoverSecondLvlAuth] = useState(false);

  const reducer = (state: any, action: any) => {
    switch (action) {
      case 'HLoaderOpen':
        return { ...state, HLoader: true };
      case 'HLoaderClose':
        return { ...state, HLoader: false };
      case 'TLoaderOpen':
        return { ...state, TLoader: true };
      case 'TLoaderClose':
        return { ...state, TLoader: false };
      case 'SOLoaderOpen':
        return { ...state, SOLoader: true };
      case 'SOLoaderClose':
        return { ...state, SOLoader: false };
      case 'UHLoaderOpen':
        return { ...state, UHLoader: true };
      case 'UHLoaderClose':
        return { ...state, UHLoader: false };
      case 'RRLoaderOpen':
        return { ...state, RRLoader: true };
      case 'RRLoaderClose':
        return { ...state, RRLoader: false };
      case 'NRRLoaderOpen':
        return { ...state, NRRLoader: true };
      case 'NRRLoaderClose':
        return { ...state, NRRLoader: false };
      case 'COLoaderOpen':
        return { ...state, COLoader: true };
      case 'COLoaderClose':
        return { ...state, COLoader: false };
      case 'CCOLoaderOpen':
        return { ...state, CCOLoader: true };
      case 'CCOLoaderClose':
        return { ...state, CCOLoader: false };
      case 'RLoaderOpen':
        return { ...state, RLoader: true };
      case 'RLoaderClose':
        return { ...state, RLoader: false };
      case 'URLoaderOpen':
        return { ...state, URLoader: true };
      case 'URLoaderClose':
        return { ...state, URLoader: false };
    }
  }

  const generateBarCodeContent = (invPriceId: any, depRefCode: any, SubDepRefCode: any) => {
    if (invPriceId) {
      return `${invPriceId}     P${depRefCode ? depRefCode : ''}${SubDepRefCode ? SubDepRefCode : ''}`;
    }
    return '';
  }

  let initialstate = {}
  const [count, dispatch] = useReducer(reducer, initialstate)

  const InitialLoad = async (inventoryNumber: any, type?: any, load?: any) => {
    try {
      if (validateObject(props) && validateObject(props.response) && validateObject(props.response.data)) {
        console.log('props-response', props.response);

        setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
        const response = type === 'Y' ? await LoadInventoryInformation(inventoryNumber) : props.response;
        setresponseLoader({ ...responseLoader, loader1: false, loader2: false })

        console.log('inventoryInfo-apiResponse', response)

        if (validateObject(response) &&
          response.status == 200 &&
          validateObject(response.data) &&
          ((validateObject(response.data.inventoryHeader) && validateObject(response.data.inventoryHeader.status)) ||
            validateObject(response.data.inventoryInformation) ||
            validateObject(response.data.currentAgreement) ||
            validateObject(response.data.chargedOff) ||
            validateObject(response.data.recovery)
          )
        ) {
          setreFreshLoader(false);
          setinventoryInformations({
            ...inventoryInformations,
            inventoryHeaderObj: response.data.inventoryHeader,
            inventoryInformationObj: response.data.inventoryInformation,
            currentAgreementObj: response.data.currentAgreement,
            chargedOffObj: response.data.chargedOff,
            recoveryObj: response.data.recovery,
            packageObj: response.data.packageDetails
          })
          //setloanCusId({...loanCusId,customerId:response.data.currentAgreement?.customerId,agreementId:response.data.currentAgreement?.agreementId})
          props.setData(response);

          if (!validateObject(response.data.inventoryHeader) && !validateObject(response.data.inventoryHeader.status)) {
            if (response.data.inventoryHeader.status == LOANER) {
              setpopUpStateForAll({
                ...popUpStateForAll,
                loanerpopup: true
              })
            }
          }

          if (validateObject(response.data.inventoryInformation)) {
            if (validateObject(response.data.inventoryInformation.auditMessage)) {
              setauditMessage(response.data.inventoryInformation.auditMessage);
            }
            if (validateObject(response.data.inventoryInformation.serialNumber)) {
              setserialNumber(response.data.inventoryInformation.serialNumber);
            }
          }
        }
        else if (response.status == 400 || response.status == 500) {
          setsomethingWentWrong(true);
        }
        else {
          setsomethingWentWrong(true);
        }
      }
      if (validateObject(props) && validateObject(props.detailsResponse) && validateObject(props.detailsResponse.data)) {
        console.log('props-response', props.detailsResponse);

        //setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
        const response = type === 'Y' && !load ? await InventoryDetails(inventoryNumber) : props.detailsResponse;
        //setresponseLoader({ ...responseLoader, loader1: false, loader2: false })

        console.log('inventoryInfo-apiResponse', response)

        // if (response.status == 400 || response.status == 500) {
        //   setsomethingWentWrong(true);
        // }

        if (validateObject(response) &&
          response.status == 200 &&
          validateObject(response.data) &&
          ((validateObject(response.data.inventoryHeader) && validateObject(response.data.inventoryHeader.status)) ||
            validateObject(response.data.depreciationEstimator) ||
            validateObject(response.data.gaapDepreciation) ||
            validateObject(response.data.idle)

          )
        ) {

          setinventoryDetails({
            ...inventoryDetails,
            inventoryHeaderObj: response.data.header,
            depreciationEstimatorObj: response.data.depreciationEstimator,
            gaapDepreciationObj: response.data.gaapDepreciation,
            idleObj: response.data.idle,

          })
        }
      }
    }
    catch {
      setsomethingWentWrong(true);
    }


  }

  useEffect(() => {
    props.setactiveTab(0);
    if (props?.response) {
      InitialLoad(inventoryNum);
    }
    console.log('props', props);
    if (validateObject(props) && validateObject(props.response) && validateObject(props.detailsResponse) && validateObject(props.activeResponse)) {

      if (props.response.status == 200 && props.detailsResponse.status == 200 && props.activeResponse.status == 200) {
        setInfoPrint(false);
        setPrintFailed(false);
      }
      else if (props.GotApiResponse && props.response == undefined || props.detailsResponse == undefined || props.activeResponse == undefined) {
        setInfoPrint(false);
        setPrintFailed(true);
      }
      else {
        setInfoPrint(false);
        setPrintFailed(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [props]);

  useEffect(() => {

    if (validateObject(props) && validateObject(props.response) && validateObject(props.response.data) && validateObject(props.response.data.inventoryHeader) && validateObject(props.response.data.inventoryHeader.status)) {
      if (chargeOffRecoveryContext.chargeOffVal.length == 0 && props.response.data.inventoryHeader.status == NOT_RENT_READY || props.response.data.inventoryHeader.status == PENDING_CHARGE_OFF) {
        getChargeOffReasons();
      }
      else {
        setinventoryChargeReason(chargeOffRecoveryContext.chargeOffVal);
      }
      if (chargeOffRecoveryContext.recoveryVal.length == 0 && props.response.data.inventoryHeader.status == CHARGED_OFF) {
        getRecoveryReasons();
      }
      else {
        setinventoryRecoveryReason(chargeOffRecoveryContext.recoveryVal);
      }
      if (chargeOffRecoveryContext.radiusValues.length == 0 && props.response.data.inventoryHeader.status == RENT_READY) {
        getRadiusStore_Api();
      }
      else {
        setradiusStore(chargeOffRecoveryContext.radiusValues);
      }
    }

  }, [props?.response])

  const getChargeOffReasons = async () => {
    setradiusStoreLoader(true);
    const COresponse: any = await getInventoryStatusReasons("chargeoff");
    setradiusStoreLoader(false);
    const chargeOffReasonsResponse = COresponse.data.chargeOffOptions;
    console.log('inventoryStatusReasonsResponse', chargeOffReasonsResponse);
    const chargeOffResponse = [
      { referenceCode: 'Select', description: 'Select' },
      ...chargeOffReasonsResponse,
    ];
    console.log('statusResponse', chargeOffResponse);
    setinventoryChargeReason(chargeOffResponse);
    setchargeOffRecoveryContext({ ...chargeOffRecoveryContext, chargeOffVal: chargeOffResponse });
  }

  const getRecoveryReasons = async () => {
    setradiusStoreLoader(true);
    const RVresponse: any = await getInventoryStatusReasons('recovery');
    setradiusStoreLoader(false);
    const recoveryReasonsResponse = RVresponse.data;
    console.log('inventoryStatusReasonsResponse', recoveryReasonsResponse);
    const recoveryResponse = [
      { referenceCode: 'Select', description: 'Select' },
      ...recoveryReasonsResponse?.recoverOptions,
    ];
    console.log('statusResponse', recoveryResponse);
    setinventoryRecoveryReason(recoveryResponse);
    setchargeOffRecoveryContext({ ...chargeOffRecoveryContext, recoveryVal: recoveryResponse });

  }


  useEffect(() => {
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      console.log('containerDataRole', containerData.GetRole());
      setcurrentCWRole(containerData?.GetRole());
      setemployeeId(containerData?.GetEmployeeId());
      const roleObj = containerData?.GetRole()
      setcoWorkerRoleForAll({
        ...coWorkerRoleForAll,
        coWorkerRoleForSN: roleObj == 'DM' || roleObj == 'RM' || roleObj == 'RD' ? true : false,
        coWorkerRoleForCF: roleObj == 'CAR' || roleObj == 'AM' || roleObj == 'LAM' || roleObj == 'SM' || roleObj == 'DMT' || roleObj == 'DM' || roleObj == 'RM' || roleObj == 'RD' ? true : false,
        coWorkerRoleForH: roleObj == 'AM' || roleObj == 'LAM' || roleObj == 'SM' || roleObj == 'DMT' || roleObj == 'DM' || roleObj == 'RM' || roleObj == 'RD' ? true : false,
        coWorkerRoleForRSVD: roleObj == 'DM' || roleObj == 'RM' || roleObj == 'RD' ? true : false,
        coWorkerRoleForR: roleObj == 'DMT' || roleObj == 'DM' || roleObj == 'RM' || roleObj == 'RD' ? true : false
      })
    } else {
      getCoWorkerRole();
    }

    // getRadiusStore_Api();
    // getInventoryStatusReasonsForDD();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCoWorkerRole = async () => {
    try {

      const currentUserRes = await getCurrentUser();
      console.log('getCurrentUser-apiResponse', currentUserRes);

      if (currentUserRes.status === 200) {
        const EmployeeId = currentUserRes.data.employeeId;
        console.log('EmployeeId', EmployeeId);

        const payload = {
          coworkerId: EmployeeId,
          storesRequired: true
        }
        console.log('menuandstores-payload', payload);
        const coWorkerRoleObj: any = await getCoworkerDetails(payload);
        if (
          validateObject(coWorkerRoleObj) && coWorkerRoleObj.status == 200 &&
          validateObject(coWorkerRoleObj.data.coworkerProfile) &&
          validateObject(coWorkerRoleObj.data.coworkerProfile.role)
        ) {
          const roleObj = coWorkerRoleObj.data.coworkerProfile.role;
          coWorkerRole = roleObj;
          console.log('roleObj', roleObj);

          setcurrentCWRole(roleObj);
          setcoWorkerRoleForAll({
            ...coWorkerRoleForAll,
            coWorkerRoleForSN: roleObj == 'DM' || roleObj == 'RM' || roleObj == 'RD' ? true : false,
            coWorkerRoleForCF: roleObj == 'CAR' || roleObj == 'AM' || roleObj == 'LAM' || roleObj == 'SM' || roleObj == 'DMT' || roleObj == 'DM' || roleObj == 'RM' || roleObj == 'RD' ? true : false,
            coWorkerRoleForH: roleObj == 'AM' || roleObj == 'LAM' || roleObj == 'SM' || roleObj == 'DMT' || roleObj == 'DM' || roleObj == 'RM' || roleObj == 'RD' ? true : false,
            coWorkerRoleForRSVD: roleObj == 'DM' || roleObj == 'RM' || roleObj == 'RD' ? true : false,
            coWorkerRoleForR: roleObj == 'DMT' || roleObj == 'DM' || roleObj == 'RM' || roleObj == 'RD' ? true : false
          })
          console.log('coWorkerRoleObj', coWorkerRole);
        }
        /* eslint-disable no-console */
        console.log('coWorkerRoleObj', coWorkerRoleObj);
      }

    } catch (e: any) {
      console.log('Error in co worker role');
    }
  };

  const transferInventorySaveClick = async () => {
    setbtnVisible(true)
    if (inventoryInformations?.packageObj.packageName != null) {
      setTransferValidatePack(true);
      setpopUpStateForAll({
        ...popUpStateForAll,
        popup: false
      })
    }
    else {
      const payload: any = {};
      if (validateObject(inventoryNum)) {
        payload.inventoryNumber = inventoryNum;
      }
      if (validateObject(storeNo)) {
        payload.fromStoreNumber = storeNo;
      }
      if (validateObject(transferState) && transferState != 'Select') {
        payload.toStoreNumber = transferState;
      }
      if (validateObject(transfer2Store) && transfer2Store != '') {
        payload.toStoreNumber = transfer2Store;
      }

      setreceivingStore(transfer2Store == '' ? transferState : transfer2Store);
      console.log('transferState', transferState);
      console.log('transfer2Store', transfer2Store);

      // const payload: any = {
      //   updateTransferInventory: updateTransferInventory
      // };

      // eslint-disable-next-line no-console
      console.log('updateTransfer-payload', payload);
      if (Object.keys(payload).length > 0) {
        //dispatch('TLoaderOpen');
        setsavebtnTransfer(true)
        const response = await updateTransfer(payload);
        //dispatch('TLoaderClose')
        setsavebtnTransfer(false)
        if (validateObject(response) && response.status === 200) {
          setpopUpStateForAll({
            ...popUpStateForAll,
            transferSuccessPopup: true,
            transferPrintPopup: false,
            popup: false
          })
        }
        else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
          if (response.data.errors[0].error == 'Package exist for inventory number') {
            seterrMsgTxt(response.data.errors[0].error)
            setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true, popup: false })
            setValidredirect(true);
          }
          else {
            setshowErrMsg(true);
            seterrMsgTxt("please enter valid StoreNumber");
            //setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true})
          }
          // setshowErrMsg(true);
          // seterrMsgTxt("please enter valid StoreNumber");


        }
        else {
          seterrMsgTxt('Something went wrong')
          setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

        }
        settransferState('Select');
        settxtDisable(false);
        setddDisable(false);
        // setshowErrMsg(false);
        settransfer2Store('');
      }
    }
  }


  const transferInventoryStatusUpdate = async () => {
    inventoryTransferClose();
    setpopUpStateForAll({
      ...popUpStateForAll,
      transferSuccessPopup: false,
      popup: false, transferPrintPopup: true
    })
    setheaderContext({
      ...headerContext,
      status: TRANSFER_IN_PROGRESS
    })
    InitialLoad(inventoryNum, 'Y', 'Y');




  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const secondLvlAuthRes = async (e: any) => {

    if (e === true) {
      switch (authenType) {
        case 'H':
          {
            const payload: any = {};
            if (validateObject(inventoryNum)) {
              payload.inventoryNumber = inventoryNum
            }
            payload.inventoryReasonRefCode = null;
            payload.inventoryStatusRefCode = 'HOLD';

            // eslint-disable-next-line no-console
            console.log('H-payload', payload);
            if (Object.keys(payload).length > 0) {
              settabDisable(true)
              dispatch('HLoaderOpen')
              const response = await updateInventoryStatus(payload);
              dispatch('HLoaderClose')
              if (validateObject(response) && response.status == 200 && validateObject(response.data) && validateObject(response.data)) {
                if (response.data) {
                  setheaderContext({
                    ...headerContext,
                    status: HOLD
                  })
                  // InitialLoad(inventoryNum, 'Y');
                  setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
                  props.PageReload('hold');
                  settabDisable(false)
                }
              }
              else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
                seterrMsgTxt(response.data.errors[0].error)
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              else {
                seterrMsgTxt('Something went wrong')
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              settabDisable(false)
            }
          }
          setsecondLvlAuth(false);
          setauthenType('');
          break;
        case 'SOR':
          {
            const payload: any = {};
            if (validateObject(inventoryNum)) {
              payload.inventoryNumber = inventoryNum
            }
            payload.inventoryReasonRefCode = null;
            payload.inventoryStatusRefCode = 'RSVD';

            // eslint-disable-next-line no-console
            console.log('H-payload', payload);
            if (Object.keys(payload).length > 0) {
              settabDisable(true)
              dispatch('SOLoaderOpen')
              const response = await updateInventoryStatus(payload);
              dispatch('SOLoaderClose')
              if (validateObject(response) && response.status == 200 && validateObject(response.data) && validateObject(response.data)) {
                if (response.data) {
                  setheaderContext({
                    ...headerContext,
                    status: RESERVED
                  })
                  InitialLoad(inventoryNum, 'Y');
                  settabDisable(false)
                }
              }
              else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
                seterrMsgTxt(response.data.errors[0].error)
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              else {
                seterrMsgTxt('Something went wrong')
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              settabDisable(false)
            }
          }
          setsecondLvlAuth(false);
          setauthenType('');
          break;
        case 'UH':
          {
            const payload: any = {};
            if (validateObject(inventoryNum)) {
              payload.inventoryNumber = inventoryNum
            }
            payload.inventoryReasonRefCode = null;
            payload.inventoryStatusRefCode = 'NRR';

            // eslint-disable-next-line no-console
            console.log('UH-payload', payload);
            if (Object.keys(payload).length > 0) {
              settabDisable(true)
              dispatch('UHLoaderOpen')
              const response = await updateInventoryStatus(payload);
              dispatch('UHLoaderClose')
              if (validateObject(response) && response.status == 200 && validateObject(response.data) && validateObject(response.data)) {
                if (response.data) {
                  setheaderContext({
                    ...headerContext,
                    status: NOT_RENT_READY
                  })
                  // InitialLoad(inventoryNum, 'Y');
                  setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
                  props.PageReload('unHold');
                  settabDisable(false)
                }
              }
              else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
                seterrMsgTxt(response.data.errors[0].error)
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              else {
                seterrMsgTxt('Something went wrong')
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              settabDisable(false)
            }
          }
          setsecondLvlAuth(false);
          setauthenType('');
          break;

        case 'RR':
          {
            const payload: any = {};
            if (validateObject(inventoryNum)) {
              payload.inventoryNumber = inventoryNum
            }
            payload.inventoryReasonRefCode = null;
            payload.inventoryStatusRefCode = 'RR';

            // eslint-disable-next-line no-console
            console.log('RR-payload', payload);
            if (Object.keys(payload).length > 0) {
              settabDisable(true)
              dispatch('RRLoaderOpen')
              const response = await updateInventoryStatus(payload);


              if (response.status == 200) {
                if (response.data) {
                  setheaderContext({
                    ...headerContext,
                    status: RENT_READY
                  })
                  // InitialLoad(inventoryNum, 'Y');
                  setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
                  location.reload();
                  // dispatch('RRLoaderClose')
                  props.PageReload('rentReady');

                  settabDisable(false)

                  // if(chargeOffRecoveryContext.radiusValues==undefined || chargeOffRecoveryContext.radiusValues==''){
                  //   getRadiusStore_Api();
                  // }
                  // else{
                  //   setradiusStore(chargeOffRecoveryContext.radiusValues);
                  // }

                }
              }
              else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
                dispatch('RRLoaderClose')
                seterrMsgTxt(response.data.errors[0].error)
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              else {
                dispatch('RRLoaderClose')
                seterrMsgTxt('Something went wrong')
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              settabDisable(false)
            }
          }
          setsecondLvlAuth(false);
          setauthenType('');
          break;

        case 'NRR':
          {
            const payload: any = {};
            if (validateObject(inventoryNum)) {
              payload.inventoryNumber = inventoryNum
            }
            payload.inventoryReasonRefCode = null;
            payload.inventoryStatusRefCode = 'NRR';

            // eslint-disable-next-line no-console
            console.log('NRR-payload', payload);
            if (Object.keys(payload).length > 0) {
              settabDisable(true)
              dispatch('NRRLoaderOpen')
              const response = await updateInventoryStatus(payload);


              if (response.status == 200) {
                if (response.data) {
                  setheaderContext({
                    ...headerContext,
                    status: NOT_RENT_READY
                  })
                  // InitialLoad(inventoryNum, 'Y');
                  setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
                  location.reload();
                  // dispatch('NRRLoaderClose')
                  props.PageReload('notRentReady');
                  settabDisable(false);

                  // if(chargeOffRecoveryContext.chargeOffVal==undefined || chargeOffRecoveryContext.chargeOffVal==''){
                  //   getChargeOffReasons();
                  // }
                  // else{
                  //   setinventoryChargeReason(chargeOffRecoveryContext.chargeOffVal);
                  // }

                }
              }
              else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
                dispatch('NRRLoaderClose')
                seterrMsgTxt(response.data.errors[0].error)
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              else {
                dispatch('NRRLoaderClose')
                seterrMsgTxt('Something went wrong')
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              settabDisable(false)
            }
          }
          setsecondLvlAuth(false);
          setauthenType('');
          break;

        case 'CO':
          {
            const payload: any = {};
            if (validateObject(inventoryNum)) {
              payload.inventoryNumber = inventoryNum
            }
            if (validateObject(chargeOffState) && chargeOffState != '') {
              if (inventoryInformations?.inventoryHeaderObj?.status == PENDING_CHARGE_OFF) {
                payload.inventoryReasonRefCode = getPendingChargeOffReasons();
              }
              else {
                payload.inventoryReasonRefCode = chargeOffState;
              }

            }
            payload.inventoryStatusRefCode = 'CO';

            // eslint-disable-next-line no-console
            console.log('CO-payload', payload);
            if (Object.keys(payload).length > 0) {
              settabDisable(true)
              dispatch('COLoaderOpen')
              const response = await updateInventoryStatus(payload);
              dispatch('COLoaderClose')

              if (response.status == 200) {
                if (response.data) {
                  setchargeOffState('Select')
                  setheaderContext({
                    ...headerContext,
                    status: CHARGED_OFF
                  })
                  // InitialLoad(inventoryNum, 'Y');
                  setreFreshLoader(true)
                  setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
                  location.reload();
                  props.PageReload('chargeOff')

                  settabDisable(false);

                  // if(chargeOffRecoveryContext.recoveryVal==undefined || chargeOffRecoveryContext.recoveryVal==''){
                  //   getRecoveryReasons();
                  // }
                  // else{
                  //   setinventoryRecoveryReason(chargeOffRecoveryContext.recoveryVal);
                  // }
                }
              }
              else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
                seterrMsgTxt(response.data.errors[0].error)
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              else {
                seterrMsgTxt('Something went wrong')
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              setchargeOffState('Select')
              settabDisable(false)
            }
          }
          setsecondLvlAuth(false);
          setauthenType('');
          break;

        case 'CCO':
          {
            const payload: any = {};
            if (validateObject(inventoryNum)) {
              payload.inventoryNumber = inventoryNum
            }
            payload.inventoryReasonRefCode = null;
            payload.inventoryStatusRefCode = 'NRR';

            // eslint-disable-next-line no-console
            console.log('CCO-payload', payload);
            if (Object.keys(payload).length > 0) {
              settabDisable(true)
              dispatch('CCOLoaderOpen')
              const response = await updateInventoryStatus(payload);
              dispatch('CCOLoaderClose')

              if (response.status == 200) {
                if (response.data) {
                  setheaderContext({
                    ...headerContext,
                    status: NOT_RENT_READY
                  })
                  // InitialLoad(inventoryNum, 'Y');
                  setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
                  location.reload();
                  props.PageReload('cancelChargeOff');

                  settabDisable(false)
                }
              }
              else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
                seterrMsgTxt(response.data.errors[0].error)
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              else {
                seterrMsgTxt('Something went wrong')
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              settabDisable(false)
            }
          }
          setsecondLvlAuth(false);
          setauthenType('');
          break;

        case 'PCO':
          {
            const payload: any = {};
            if (validateObject(inventoryNum)) {
              payload.inventoryNumber = inventoryNum
            }
            if (validateObject(chargeOffState) && chargeOffState != '') {
              payload.inventoryReasonRefCode = chargeOffState;
            }
            payload.inventoryStatusRefCode = 'PCO';

            // eslint-disable-next-line no-console
            console.log('PCO-payload', payload);
            if (Object.keys(payload).length > 0) {
              settabDisable(true)
              dispatch('COLoaderOpen')
              const response = await updateInventoryStatus(payload);
              dispatch('COLoaderClose')

              if (response.status == 200) {
                if (response.data) {
                  setchargeOffState('Select')
                  setheaderContext({
                    ...headerContext,
                    status: PENDING_CHARGE_OFF
                  })
                  // InitialLoad(inventoryNum, 'Y');
                  setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
                  location.reload();
                  props.PageReload('pendingChargeOff');
                  settabDisable(false)
                }
              }
              else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
                seterrMsgTxt(response.data.errors[0].error)
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              else {
                seterrMsgTxt('Something went wrong')
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              setchargeOffState('Select')
              settabDisable(false)
            }
          }
          setsecondLvlAuth(false);
          setauthenType('');
          break;

        case 'R':
          {
            const payload: any = {};
            if (validateObject(inventoryNum)) {
              payload.inventoryNumber = inventoryNum
            }
            if (validateObject(recoverReasonState)) {
              payload.inventoryReasonRefCode = recoverReasonState;
            }
            payload.inventoryStatusRefCode = 'NRR';

            // eslint-disable-next-line no-console
            console.log('R-payload', payload);
            if (Object.keys(payload).length > 0) {
              settabDisable(true)
              dispatch('RLoaderOpen')
              const response = await updateInventoryStatus(payload);
              dispatch('RLoaderClose')

              if (validateObject(response) && response.status == 200 && validateObject(response.data) && validateObject(response.data.inventoryStatusRefCode)) {
                if (response.data.inventoryStatusRefCode === 'NRR') {
                  setheaderContext({
                    ...headerContext,
                    status: NOT_RENT_READY
                  })
                  InitialLoad(inventoryNum, 'Y');
                  settabDisable(false)
                }
              }
              else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
                seterrMsgTxt(response.data.errors[0].error)
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              else {
                seterrMsgTxt('Something went wrong')
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              setrecoverReasonState('Select');
              settabDisable(false)
            }
          }
          setsecondLvlAuth(false);
          setauthenType('');
          break;

        case 'UR':
          {
            const payload: any = {};
            if (validateObject(inventoryNum)) {
              payload.inventoryNumber = inventoryNum
            }
            payload.inventoryReasonRefCode = null;
            payload.inventoryStatusRefCode = 'NRR';

            // eslint-disable-next-line no-console
            console.log('UR-payload', payload);
            if (Object.keys(payload).length > 0) {
              settabDisable(true)
              dispatch('URLoaderOpen')
              const response = await updateInventoryStatus(payload);
              dispatch('URLoaderClose')

              if (response?.status == 200) {
                if (response.data) {
                  setheaderContext({
                    ...headerContext,
                    status: NOT_RENT_READY
                  })
                  setreFreshLoader(true);
                  setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
                  props.PageReload('unReserved');
                  settabDisable(false);
                }
              }
              else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
                seterrMsgTxt(response.data.errors[0].error)
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              else {
                seterrMsgTxt('Something went wrong')
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

              }
              settabDisable(false)
            }
          }
          setsecondLvlAuth(false);
          setauthenType('');
          break;

        case 'T':
          {
            setpopUpStateForAll({
              ...popUpStateForAll,
              popup: true
            })
          }
          setsecondLvlAuth(false);
          setauthenType('');
          break;

        default:
          {
            console.log('Invalid User Login');
          }
      }
    }
    else {
      console.log('Invalid User Login');
    }
  }

  const getPendingChargeOffReasons = () => {
    let pendingChargeOffReason = 'Select';
    inventoryChargeReason.map((entry: any) => {
      if (entry.description == inventoryInformations.chargedOffObj.reason) {
        pendingChargeOffReason = entry.referenceCode
      }
      // return { label: entry.description, value: entry.referenceCode };
    });
    return pendingChargeOffReason;
  }

  const inventoryChargeoffReason = () => {
    if (validateObject(inventoryChargeReason)) {
      return inventoryChargeReason.map((entry: any) => {
        return { label: entry.description, value: entry.referenceCode };
      });
    } else {
      return [{ label: "Select", value: "Select" }];
    }
  };

  const inventoryRecoveryReasonFunction = () => {
    if (validateObject(inventoryRecoveryReason)) {
      return inventoryRecoveryReason.map((entry: any) => {
        return { label: entry.description, value: entry.referenceCode };
      });
    } else {
      return [{ label: "Select", value: "Select" }];
    }
  };

  // Need to uncommand once getradiusstore api works in dev env...
  const getRadiusStore_Api = async () => {
    const currentStore = sessionStorage.getItem('storeNumber');
    setradiusStoreLoader(true);
    const response: any = await getRadiusStore(currentStore);
    setradiusStoreLoader(false);
    console.log('getRadiusStore-apiResponse', response);
    const radiusStoreAry = response.data.response;
    const radiusStoreAdd = [
      { neighborStoreId: '0', neighborStoreNumber: 'Select' },
      ...radiusStoreAry,
    ];
    setradiusStore(radiusStoreAdd);
    setchargeOffRecoveryContext({ ...chargeOffRecoveryContext, radiusValues: radiusStoreAdd });

  };

  const getInventoryStatusReasonsForDD = async () => {
    setradiusStoreLoader(true);
    const COresponse: any = await getInventoryStatusReasons("chargeoff");

    console.log('getInventoryStatusReasons-apiResponse', COresponse);
    const RVresponse: any = await getInventoryStatusReasons('recovery');
    setradiusStoreLoader(false);
    const chargeOffReasonsResponse = COresponse.data;
    console.log('inventoryStatusReasonsResponse', chargeOffReasonsResponse);
    const chargeOffResponse = [
      { referenceCode: 'Select', description: 'Select' },
      ...chargeOffReasonsResponse,
    ];
    /* eslint-disable no-console */
    console.log('statusResponse', chargeOffResponse);
    setinventoryChargeReason(chargeOffResponse)

    const recoveryReasonsResponse = RVresponse.data;
    console.log('inventoryStatusReasonsResponse', recoveryReasonsResponse);
    const recoveryResponse = [
      { referenceCode: 'Select', description: 'Select' },
      ...recoveryReasonsResponse,
    ];
    /* eslint-disable no-console */
    console.log('statusResponse', recoveryResponse);
    setinventoryRecoveryReason(recoveryResponse);

  };

  const radiusStoreArrayFunction = () => {
    if (validateObject(radiusStore)) {
      return radiusStore.map((value: any) => {
        return { label: value.neighborStoreNumber, value: value.neighborStoreNumber };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  };

  const onClickCancelBtn = () => {
    history.push(`/inventory/inventorysearch`)
  }

  const roundOff = (num: any, places: number) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
  }

  const recoverSaveClick = async () => {
    try {
      setresponseLoader({ ...responseLoader, loader2: true });
      const recoverPayload = {
        inventoryNumber: inventoryNum,
        inventoryStatusRefCode: 'NRR',
        inventoryReasonRefCode: recoverReasonState
      }
      const updateInvStatusResponse = await updateInventoryStatus(recoverPayload);
      setresponseLoader({ ...responseLoader, loader2: false });
      if (updateInvStatusResponse.status == 200) {
        window.location.reload();
      }
      else {
        setsomethingWentWrong(true);
      }
    }
    catch {
      setsomethingWentWrong(true);
    }
  }

  const recoverPopUp = () => {
    return (
      <>
        <Grid className={`${classes.modalBody} ${classes.mb5} ${classes.mtm28}`}>
          <Grid className={`${classes.racCol12} ${classes.px0}`}>
            <Typography className={`${classes.formLabel} ${classes.fontBold}`}>Select Reason</Typography>
            <RACSelect
              options={inventoryRecoveryReasonFunction()}
              loading={radiusStoreLoader}
              defaultValue={recoverReasonState !== "" ? recoverReasonState : 'Select'}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                e.target.value == "Select" ?
                  setrecoverReasonState('Select') :
                  setrecoverReasonState(e.target.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid className={`${classes.racCol12} ${classes.px3} ${classes.textRight}`} style={{ marginTop: '-30px' }}>
          <RACButton
            className={`${classes.pt9} ${classes.pb9}`}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              setpopUpStateForAll({
                ...popUpStateForAll,
                recoverpopup: false
              })
              setrecoverReasonState('Select')
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            disabled={recoverReasonState == 'Select' ? true : false}
            className={`${classes.pt9} ${classes.pb9} ${classes.ml6}`}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setpopUpStateForAll({
                ...popUpStateForAll,
                recoverpopup: false
              })
              recoverSaveClick();
            }}
          >
            Save
          </RACButton>
        </Grid>
      </>
    )
  }


  const handleChangeT2S = (e: any) => {
    if (e.target.value.length === 5) {
      setbtnVisible(false)
    }
    else {
      setbtnVisible(true)
    }
    if (e.target.value !== "") {
      setddDisable(true)
      setshowErrMsg(false)
    }
    else {
      setddDisable(false)
    }
    settransfer2Store(e.target.value.replace(/\D/g, ''));
  }

  const transferInventorySaveBtn = () => {
    setpopUpStateForAll({
      ...popUpStateForAll,
      transferPrintPopup: false
    })
    history.push(`/inventory/inventorysearch`)
  }


  const radiusOnChange = (e: any) => {
    if (e.target.value == "Select") {
      settransferState('Select')
      setbtnVisible(true)
    }
    else {
      settransferState(e.target.value)
      setbtnVisible(false)
      settxtDisable(true)
    }
  }
  const inventoryTransferPopUp = () => {
    return (
      <>
        <Grid className={classes.modalBody}>
          <Grid className={`${classes.racCol12} ${classes.pb2} ${classes.px4}`}>
            <Typography className={classes.formLabel}>Stores in Radius</Typography>
            <RACSelect
              isDisabled={ddDisable}
              options={radiusStoreArrayFunction()}
              defaultValue={transferState !== "" ? transferState : 'Select'}
              loading={radiusStoreLoader}
              // onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              //   e.target.value == "Select" ?
              //     settransferState('Select') :
              //     settransferState(e.target.value)
              //   settxtDisable(true)
              // }}
              onChange={(e: any) => radiusOnChange(e)}
            />
            <Grid className={`${classes.textCenter} ${classes.mt20} ${classes.mb20}`}>
              <Typography variant="h6">Or</Typography>
            </Grid>
            <Typography className={classes.formLabel}>Transfer to Store</Typography>
            <RACTextbox
              type="text"
              className={classes.w100}
              disabled={txtDisable && transferState !== 'Select'}
              name="transferInStore"
              id="a11y_transferInStore"
              value={transfer2Store}
              maxlength={5}
              OnChange={(e: any) => handleChangeT2S(e)}
              // Onblur={() => setddDisable(true)}
              isCurrency={false}
              errorMessage={showErrMsg ? errMsgTxt : ''}
            />
            {/* {showErrMsg && transfer2Store.length === 5 ? <Typography className={classes.textRed}>Invalid Store #</Typography> : null} */}
          </Grid>
        </Grid>
        <Grid className={`${classes.racCol12} ${classes.pb4} ${classes.px3} ${classes.textCenter}`}>
          <RACButton
            variant="outlined"
            color="secondary"
            className={`${classes.mr45} ${classes.pt9} ${classes.pb9}`}
            onClick={() => inventoryTransferClose()}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            loading={savebtnTransfer}
            className={`${classes.pt9} ${classes.pb9}`}
            disabled={btnVisible}
            // disabled={(btnVisible && transfer2Store.length === 5) || transferState !== 'Select' ? false : true}
            onClick={() => transferInventorySaveClick()}
          >
            Save
          </RACButton>
        </Grid>
      </>
    )
  }

  const inventoryTransferSavePopUp = () => {
    return (
      <Grid className={classes.mtm20}>
        <Grid className={classes.modalBody}>
          <Grid className={`${classes.modalTitle} ${classes.textCenter}`}>
            <Typography className={classes.fontBold} variant="h6">Transfer request sent successfully.</Typography>
          </Grid>
        </Grid>
        <Grid className={`${classes.racCol12} ${classes.pb4} ${classes.px3} ${classes.textCenter} ${classes.mt7} ${classes.mbm10}`}>
          <RACButton
            className={`${classes.mx1} ${classes.pb1} ${classes.pt1}`}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => transferInventoryStatusUpdate()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    )
  }

  const TransferpackageValidatePopUp = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            variant={'body2'}
            className={`${classes.my3} ${classes.RACPOPMsg}`}
          >
            Item {inventoryInformations?.inventoryHeaderObj.itemNumber} is part of {inventoryInformations?.packageObj?.packageName} package. Please undo/break the package and try again
          </Typography>
        </Grid>
        <Grid
          item
          className={`${classes.textCenter} ${classes.mt4} ${classes.w100}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => setTransferValidatePack(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const saveSuccessfullPopUp = () => {
    return (
      <>
        <Grid className={classes.modalBody}>
          <Grid className={`${classes.modalTitle} ${classes.textCenter}`}>
            <Grid className={`${classes.racCol12} ${classes.mb10}`} >
              <SuccessIcon />
            </Grid>
            <Grid className={`${classes.racCol12} ${classes.mb12}`}>
              <Typography variant="h6">Changes Saved Successfully</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={`${classes.racCol12} ${classes.pb4} ${classes.px3} ${classes.textCenter}`}>
          <RACButton
            className={`${classes.mx1} ${classes.pb1} ${classes.pt1}`}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => closeSavePopUp()}
          >
            Ok
          </RACButton>
        </Grid>
      </>
    )
  }

  const chargeOffPopUp = () => {
    return (
      <>
        <Grid className={`${classes.modalBody} ${classes.mb5} ${classes.mtm28}`}>
          <Grid className={`${classes.racCol12} ${classes.px0}`}>
            <Typography className={`${classes.formLabel} ${classes.fontBold}`}>Select Reason</Typography>
            <RACSelect
              options={inventoryChargeoffReason()}
              defaultValue={chargeOffState !== "" ? chargeOffState : 'Select'}
              loading={radiusStoreLoader}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                e.target.value == "Select" ?
                  setchargeOffState('Select') :
                  setchargeOffState(e.target.value)
              }}
            />
          </Grid>
        </Grid>

        <Grid className={`${classes.racCol12} ${classes.px3} ${classes.textRight}`} style={{ marginTop: '-30px' }}>
          <RACButton
            className={`${classes.pt9} ${classes.pb9}`}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              setpopUpStateForAll({
                ...popUpStateForAll,
                chargeoffpopup: false
              })
              setchargeOffState('Select')
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            disabled={chargeOffState == 'Select' ? true : false}
            className={`${classes.pt9} ${classes.pb9} ${classes.ml6}`}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setpopUpStateForAll({
                ...popUpStateForAll,
                chargeoffpopup: false,
                chargeoffConfirmpopup: true
              })
            }}
          >
            Save
          </RACButton>
        </Grid>
      </>
    )
  }

  const chargeOffConfirm = () => {
    return (
      <Grid>
        <Grid className={`${classes.modalBody} ${classes.popUpMainDiv}`}>
          <Grid className={`${classes.modalTitle} ${classes.textCenter}`}>
            <Typography variant="h6">Are you sure you want<br />to submit?</Typography>
          </Grid>
        </Grid>
        <Grid className={`${classes.racCol12} ${classes.textCenter} ${classes.pt16}`}>
          <RACButton
            className={`${classes.mx1} ${classes.pb1} ${classes.pt1}`}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              setpopUpStateForAll({
                ...popUpStateForAll,
                chargeoffConfirmpopup: false
              })
              setchargeOffState('Select')
            }}
          >
            No
          </RACButton>
          <RACButton
            className={`${classes.mx1} ${classes.pb1} ${classes.pt1}`}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setpopUpStateForAll({
                ...popUpStateForAll,
                chargeoffConfirmpopup: false
              })
              onClickForStatusChange(coWorkerRoleForAll.coWorkerRoleForSN === true ? 'CO' : 'PCO', 'Item ChargedOff And Cancel')
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    )
  }

  const chargeOffCancel = () => {
    return (
      <Grid>
        <Grid className={`${classes.modalBody} ${classes.popUpMainDiv}`}>
          <Grid className={`${classes.modalTitle} ${classes.textCenter}`}>
            <Typography variant="h6">Are you sure you want<br />to cancel Charge Off?</Typography>
          </Grid>
        </Grid>
        <Grid className={`${classes.racCol12} ${classes.textCenter} ${classes.pt16}`}>
          <RACButton
            className={`${classes.mx1} ${classes.pb1} ${classes.pt1}`}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() =>
              setpopUpStateForAll({
                ...popUpStateForAll,
                chargeoffCancelpopup: false
              })
            }
          >
            No
          </RACButton>
          <RACButton
            className={`${classes.mx1} ${classes.pb1} ${classes.pt1}`}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setpopUpStateForAll({
                ...popUpStateForAll,
                chargeoffCancelpopup: false
              })
              onClickForStatusChange('CCO', 'Item ChargedOff And Cancel')
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    )
  }

  const loanerPopUp = () => {
    return (
      <>
        <Grid className={`${classes.modalBody} ${classes.popUpMainDiv}`}>
          <Grid className={`${classes.modalTitle} ${classes.textCenter}`}>
            <Typography variant="h6">Pricing not available for this item.<br />Please contact the Helpdesk for assistance</Typography>
          </Grid>
        </Grid>
        <Grid className={`${classes.racCol12} ${classes.textCenter} ${classes.pt14} ${classes.pb10}`}>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            className={`${classes.mx1} ${classes.pb1} ${classes.pt1}`}
            onClick={() =>
              setpopUpStateForAll({
                ...popUpStateForAll,
                loanerpopup: false
              })
            }
          >
            Ok
          </RACButton>
        </Grid>
      </>
    )
  }

  const onHandleChange = (e: any) => {
    if (e.target.name == "serialnumber") {
      setserialNumber(e.target.value);
      setenableSaveBtnForAuditMsg(true);
      // if((e.target.value).length > 0){
      //   setenableSaveBtnForAuditMsg(true);
      // }
    }
    if (e.target.name == "auditMessage") {

      setauditMessage(e.target.value);
      setenableSaveBtnForAuditMsg(true);
      // if((e.target.value).length > 0){
      //   setenableSaveBtnForAuditMsg(true);
      // }
    }
  }

  const onClickSaveBtn = async () => {
    const payload: any = {};
    if (validateObject(inventoryNum)) {
      payload.inventoryNumber = inventoryNum
    }
    if (validateObject(auditMessage)) {
      payload.auditMessage = auditMessage;
    }
    if (validateObject(serialNumber) && validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryHeaderObj.status) && inventoryInformations.inventoryHeaderObj.status == NOT_RENT_READY) {
      payload.serialNumber = serialNumber;
    }


    // eslint-disable-next-line no-console
    console.log('saveInventoryInfo-payload', payload);
    if (Object.keys(payload).length > 0) {
      settabDisable(true)
      setsavebtnLoader(true)
      setenableSaveBtnForAuditMsg(true)
      const response = await saveInventoryInfo(payload);
      setsavebtnLoader(false)
      setenableSaveBtnForAuditMsg(false)
      console.log('saveInventoryInfo-apiResponse', response)
      if (validateObject(response) && response.status == 200 && validateObject(response.data) && validateObject(response.data.inventoryId)) {
        setpopUpStateForAll({
          ...popUpStateForAll,
          savePopupState: true
        })
      }
      else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
        seterrMsgTxt(response.data.errors[0].error)
        setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

      }
      else {
        seterrMsgTxt('Something went wrong')
        setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: true })

      }
    }
  }

  const inventoryTransferClose = () => {
    setpopUpStateForAll({
      ...popUpStateForAll,
      popup: false
    })
    settransferState('Select');
    settxtDisable(false);
    setddDisable(false);
    setshowErrMsg(false);
    settransfer2Store('');
  }

  const openTransferPrintPopUp = () => {
    setpopUpStateForAll({
      ...popUpStateForAll,
      popup: false,
      transferPrintPopup: true
    })
  }

  const PrintPopupOpen = () => {
    return (
      <div>
        <Grid container spacing={2} className={classes.p3}>
          <Grid item md={12}>
            <PDFImages className={classes.me2}></PDFImages>
            <Typography variant="caption" className={classes.formLabel}>
              Transfer Form
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById('transferFormInnerHtml'))
              }
            >
              View PDF
            </RACButton>
            {/* <>
              <ReactToPrint
                trigger={() =>
                  <RACButton
                    className={classes.agrPrintDoc}
                  >
                    View PDF
                  </RACButton>}
                content={() => componentRef.current}
                documentTitle="Transfer In Form"
              />
            </> */}
          </Grid>
          <Grid item md={12}>
            <PDFImages className={classes.me2}></PDFImages>
            <Typography variant="caption" className={classes.formLabel}>
              Transfer Out Form
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById('transferOutFormInnerHtml'))
              }
            >
              View PDF
            </RACButton>
            {/* <>
              <ReactToPrint
                trigger={() =>
                  <RACButton
                    className={classes.agrPrintDoc}
                  >
                    View PDF
                  </RACButton>}
                content={() => componentRef.current}
                documentTitle="Transfer Out Form"
              />

            </> */}
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => transferInventorySaveBtn()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const closeSavePopUp = () => {
    setpopUpStateForAll({
      ...popUpStateForAll,
      savePopupState: false
    })
    setreFreshLoader(true);
    //InitialLoad(inventoryNum, 'Y');
    setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
    props.PageReload()
    setenableSaveBtnForAuditMsg(false);
    settabDisable(false)
  }

  const onClickForStatusChange = (authType: any, module: string) => {
    setsecondLvlAuth(true);
    setauthenType(authType);
    setmoduleName(module);
  }

  const serialNumberDisable = () => {
    if (inventoryInformations?.inventoryHeaderObj?.status == NOT_RENT_READY && coWorkerRoleForAll?.coWorkerRoleForSN) {
      return false
    }
    else {
      return true
    }

  }

  const SomethingWentWrongPopUp = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <AlertIcon></AlertIcon>
          <Typography
            variant={'body2'}
            className={`${classes.my3} ${classes.RACPOPMsg}`}
          >
            {errMsgTxt}
          </Typography>
        </Grid>
        <Grid
          item
          className={`${classes.textCenter} ${classes.mt4} ${classes.w100}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => {
              setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: false });
              seterrMsgTxt('');
              setchargeOffState('Select');
              setrecoverReasonState('Select');
              if (Validredirect) {
                setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: false, popup: true })
              }
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };



  const PrintFailPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <AlertIcon></AlertIcon>
          <Typography
            variant={'body2'}
            className={`${classes.my3} ${classes.RACPOPMsg}`}
          >
            {"Sorry!! Unable to print at this moment!"}
          </Typography>
        </Grid>
        <Grid
          item
          className={`${classes.textCenter} ${classes.mt4} ${classes.w100}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => {
              setPrintFailOpen(false);
            }
            }
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {responseLoader.loader1 || responseLoader.loader2 == true || reFreshLoader ?
        <div>
          <div className={classes.racLoaderPage}>
            <CircularProgress style={{ marginTop: '0px' }} />
          </div>
        </div>
        :
        somethingWentWrong == true ?
          <>
            <div className={`${classes.textCenter} ${classes.mt5}`}>
              <AlertIcon />
              <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                Something Went Wrong
              </Typography>
            </div>
          </>
          :
          <Grid>
            <Grid container className={classes.mbFooterSpacing}>
              <Grid item md={12}>

                {/* Inventory information starts here */}

                {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryInformationObj) ?
                  <Card className={`${classes.m2} ${classes.racCard}`}>
                    <CardContent>
                      <Grid container spacing={4} style={{ marginBottom: '10px' }} className={classes.alignCenter}>

                        <Grid item xs={12} sm={4} md={4} lg={2} className={`${classes.alignCenter} `}>
                          <Typography className={form.formLabel}>Serial Number </Typography>

                          <Grid className={`${classes.dflex} ${classes.alignCenter} ${form.customFormTextBox}`} style={{ marginLeft: '-2px', width: 'max-content' }}>
                            <RACTextbox
                              type="text"
                              className={`${classes.w100} ${classes.borderRadius4px}`}
                              name="serialnumber"
                              id="a11y_serialno"
                              value={serialNumber}
                              // disabled={validateObject(inventoryInformations.inventoryHeaderObj.status) && inventoryInformations.inventoryHeaderObj.status == NOT_RENT_READY && (coWorkerRoleForAll.coWorkerRoleForSN == true) ? false : true}
                              disabled={inventoryInformations.inventoryHeaderObj.storeNumber !== storeNo ? true : serialNumberDisable()}
                              maxlength={20}
                              OnChange={(e: any) => onHandleChange(e)}
                              isCurrency={false}
                            />
                          </Grid>

                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                          <Typography className={form.formLabel}>Model Number </Typography>
                          <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.inventoryInformationObj.modelNumber) && inventoryInformations.inventoryInformationObj.modelNumber.length > 25 ? inventoryInformations.inventoryInformationObj.modelNumber : ''}>{validateObject(inventoryInformations.inventoryInformationObj.modelNumber) ? truncString(inventoryInformations.inventoryInformationObj.modelNumber, 25, '...') : '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                          <Typography className={form.formLabel}>Department </Typography>
                          <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.inventoryInformationObj.department) && inventoryInformations.inventoryInformationObj.department.length > 25 ? inventoryInformations.inventoryInformationObj.department : ''} >{validateObject(inventoryInformations.inventoryInformationObj.department) ? truncString(inventoryInformations.inventoryInformationObj.department, 25, '...') : '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                          <Typography className={form.formLabel}>Sub Department </Typography>
                          <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.inventoryInformationObj.subDepartment) && inventoryInformations.inventoryInformationObj.subDepartment.length > 25 ? inventoryInformations.inventoryInformationObj.subDepartment : ''}>{validateObject(inventoryInformations.inventoryInformationObj.subDepartment) ? truncString(inventoryInformations.inventoryInformationObj.subDepartment, 25, '...') : '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                          <Typography className={form.formLabel}>Brand </Typography>
                          <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.inventoryInformationObj.brand) && inventoryInformations.inventoryInformationObj.brand.length > 25 ? inventoryInformations.inventoryInformationObj.brand : ''}>{validateObject(inventoryInformations.inventoryInformationObj.brand) ? truncString(inventoryInformations.inventoryInformationObj.brand, 25, '...') : '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                          <Typography className={form.formLabel}>Bracket </Typography>
                          <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.inventoryInformationObj.bracket) && inventoryInformations.inventoryInformationObj.bracket.length > 25 ? inventoryInformations.inventoryInformationObj.bracket : ''}>{validateObject(inventoryInformations.inventoryInformationObj.bracket) ? truncString(inventoryInformations.inventoryInformationObj.bracket, 25, '...') : '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                          <Typography className={form.formLabel}>Current Term </Typography>
                          <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.inventoryInformationObj.currentTerm) && inventoryInformations.inventoryInformationObj.currentTerm !== 0 ? inventoryInformations.inventoryInformationObj.currentTerm : '0'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                          <Typography className={form.formLabel}>Current Rate </Typography>
                          <Typography className={`${form.formValue} ${classes.mt2}`}>
                            {validateObject(inventoryInformations.inventoryInformationObj.currentRate) && (inventoryInformations.inventoryInformationObj.currentRate !== "0" || inventoryInformations.inventoryInformationObj.currentRate !== "0.00") ?
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={roundOff(inventoryInformations.inventoryInformationObj.currentRate, 3).toFixed(2)}
                                prefix="$ "
                                className={classes.formLabelValue}
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                              /> : "$ 0.00"}
                          </Typography>
                        </Grid>
                        {featureFlagDetails[ADD_ON_PRICING] == '1' ? <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                          <Typography className={form.formLabel}>Add-On Rate</Typography>
                          <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.inventoryInformationObj.currentAddOnRate) && !(isNaN(inventoryInformations.inventoryInformationObj.currentAddOnRate)) ? `$ ${inventoryInformations.inventoryInformationObj.currentAddOnRate}` : '-'}</Typography>
                        </Grid> : null}
                      </Grid>


                      <Grid container spacing={4} className={classes.alignCenter}>
                        <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>

                          <Typography variant="subtitle1" className={form.formLabel}>Audit Message</Typography>
                          <textarea
                            className={`${classes.w100} ${form.formTextArea}`}
                            style={{ padding: '4px !important' }}
                            id="exampleFormControlTextarea1"
                            name="auditMessage"
                            // maxLength={250}
                            // rows={3}
                            value={auditMessage}
                            maxLength={255}
                            onChange={(e: any) => onHandleChange(e)}
                          ></textarea>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
                          {inventoryInformations.inventoryHeaderObj.storeNumber === storeNo ?
                            <>
                              {validateObject(inventoryInformations.inventoryHeaderObj) && inventoryInformations.inventoryHeaderObj.status == RENT_READY ?

                                <Grid className={`${classes.componentRight} ${classes.mt57}`}>

                                  {saleslead ?
                                    <>
                                      <RACButton
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        loading={count.SOLoader}
                                        disabled={count.SOLoader === true ? true : false}
                                        className={classes.ms2}
                                        onClick={() => onClickForStatusChange('SOR', 'Rent Ready To Not Rent Ready')}
                                      >
                                        S/O Reserve
                                      </RACButton>
                                      <RACButton
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        loading={count.RRLoader}
                                        disabled={count.RRLoader === true ? true : false}
                                        className={classes.ms2}
                                        onClick={() => onClickForStatusChange('SOR', 'Rent Ready To Not Rent Ready')}
                                      >
                                        S/O Tranfer
                                      </RACButton>
                                    </> :
                                    <>
                                      <RACButton
                                        variant="contained"
                                        color="primary"
                                        loading={count.NRRLoader}
                                        disabled={count.NRRLoader === true || count.HLoader === true ? true : false}
                                        size="small"
                                        onClick={() => onClickForStatusChange('NRR', 'Rent Ready')}
                                      >
                                        Not Rent Ready
                                      </RACButton>
                                      <RACButton
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        loading={count.HLoader}
                                        disabled={count.HLoader === true || count.NRRLoader === true ? true : false}
                                        className={classes.ms2}
                                        onClick={() => {
                                          onClickForStatusChange('H', 'Hold To Unhold Inventory');
                                        }}
                                      >
                                        Hold
                                      </RACButton>
                                      {transferinvFeature == '1' ? <RACButton
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        disabled={count.HLoader === true || count.NRRLoader === true ? true : false}
                                        className={classes.ms2}
                                        onClick={() => setpopUpStateForAll({
                                          ...popUpStateForAll,
                                          popup: true
                                        })}
                                      >
                                        Transfer
                                      </RACButton> : null}
                                    </>}
                                </Grid>
                                : null}
                              {/* commented */}
                              {/* {validateObject(inventoryInformations.inventoryHeaderObj) && inventoryInformations.inventoryHeaderObj.status == NOT_RENT_READY ?
                            <Grid item xs={12} sm={12} md={12} lg={12} className={`${classes.componentRight} ${classes.mt57}`}>
                              <RACButton
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.ms1}
                                onClick={() => printHTML(document.getElementById('printRentReadyInlineHtml'))}
                              >
                                Print Rent Ready
                              </RACButton>
                                                {/* <>
                                                  <ReactToPrint
                                                    trigger={() =>
                                                      <RACButton
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        className={classes.ms1}
                                                      >
                                                        Print Rent Ready
                                                      </RACButton>}
                                                    content={() => componentRef.current}
                                                    documentTitle="Print Rent Ready"
                                                  />
                                                </> *
                              <RACButton
                                variant="contained"
                                color="primary"
                                size="small"
                                loading={count.RRLoader}
                                disabled={count.RRLoader === true ? true : false}
                                className={classes.ms2}
                                onClick={() => onClickForStatusChange('RR', 'Rent Ready To Not Rent Ready')}
                              >
                                Rent Ready
                              </RACButton>
                              {salesLeadId?
                              <RACButton
                                variant="contained"
                                color="primary"
                                size="small"
                                loading={count.RRLoader}
                                disabled={count.RRLoader === true ? true : false}
                                className={classes.ms2}
                                onClick={() => onClickForStatusChange('RR', 'Rent Ready To Not Rent Ready')}
                              >
                                S/O Reserve
                              </RACButton>:null}

                              {((inventoryInformations?.inventoryHeaderObj.status == NOT_RENT_READY) || (inventoryInformations?.inventoryHeaderObj.status == PENDING_CHARGE_OFF || inventoryInformations?.inventoryHeaderObj.status == NOT_RENT_READY && coWorkerRoleForAll.coWorkerRoleForR == true))?
                                <RACButton
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  loading={count.COLoader}
                                  disabled={count.COLoader === true ? true : false}
                                  className={classes.ms2}
                                  onClick={() => setpopUpStateForAll({
                                    ...popUpStateForAll,
                                    chargeoffpopup: true
                                  })}
                                >
                                  Charge Off
                                </RACButton> : null}
                            </Grid>
                            : null} *  
                          {validateObject(inventoryInformations.inventoryHeaderObj) && inventoryInformations.inventoryHeaderObj.status == PENDING_CHARGE_OFF ?
                            <Grid className={`${classes.componentRight} ${classes.mt57}`}>
                              {coWorkerRoleForAll.coWorkerRoleForCF == true ?
                                <RACButton
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  loading={count.CCOLoader}
                                  disabled={count.CCOLoader === true ? true : false}
                                  className={`${classes.ms2} ${classes.floatRight}`}
                                  onClick={() =>
                                    setpopUpStateForAll({
                                      ...popUpStateForAll,
                                      chargeoffCancelpopup: true
                                    })
                                  }
                                >
                                  Cancel Charge Off
                                </RACButton> : null}
                            </Grid>
                            : null} */}
                              {/* added */}

                              <Grid item xs={12} sm={12} md={12} lg={12} className={`${classes.componentRight} ${classes.mt57}`}>
                                {validateObject(inventoryInformations.inventoryHeaderObj) && inventoryInformations.inventoryHeaderObj.status == NOT_RENT_READY ?
                                  (<>
                                    <RACButton
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      className={classes.ms1}
                                      onClick={() => printHTML(document.getElementById('printRentReadyInlineHtml'))}
                                    >
                                      Print Rent Ready
                                    </RACButton>
                                    <RACButton
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      loading={count.RRLoader}
                                      disabled={count.RRLoader === true || count.COLoader === true ? true : false}
                                      className={classes.ms2}
                                      onClick={() => onClickForStatusChange('RR', 'Rent Ready To Not Rent Ready')}
                                    >
                                      Rent Ready
                                    </RACButton>
                                    <RACButton
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      loading={count.COLoader}
                                      disabled={count.COLoader === true || count.RRLoader === true ? true : false}
                                      className={classes.ms2}
                                      onClick={() => setpopUpStateForAll({
                                        ...popUpStateForAll,
                                        chargeoffpopup: true
                                      })}
                                    >
                                      Charge Off
                                    </RACButton>

                                  </>) : null}

                                {((inventoryInformations?.inventoryHeaderObj.status == PENDING_CHARGE_OFF) && coWorkerRoleForAll.coWorkerRoleForR == true) ?
                                  <RACButton
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    loading={count.COLoader}
                                    disabled={count.COLoader === true ? true : false}
                                    className={classes.ms2}
                                    // onClick={() => setpopUpStateForAll({
                                    //   ...popUpStateForAll,
                                    //   chargeoffpopup: true
                                    // })}
                                    onClick={() => onClickForStatusChange('CO', 'Item ChargedOff And Cancel')}
                                  >
                                    Approve Charge Off
                                  </RACButton> : null}


                                {validateObject(inventoryInformations.inventoryHeaderObj) && inventoryInformations.inventoryHeaderObj.status == PENDING_CHARGE_OFF ?
                                  <Grid className={`${classes.componentRight} `}>
                                    {coWorkerRoleForAll.coWorkerRoleForCF == true ?
                                      <RACButton
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        loading={count.CCOLoader}
                                        disabled={count.CCOLoader === true ? true : false}
                                        className={`${classes.ms2} ${classes.floatRight}`}
                                        onClick={() =>
                                          setpopUpStateForAll({
                                            ...popUpStateForAll,
                                            chargeoffCancelpopup: true
                                          })
                                        }
                                      >
                                        Cancel Charge Off
                                      </RACButton> : null}
                                  </Grid>
                                  : null}
                              </Grid>


                              {validateObject(inventoryInformations.inventoryHeaderObj) && inventoryInformations.inventoryHeaderObj.status == HOLD ?
                                <Grid className={`${classes.componentRight} ${classes.mt57}`}>
                                  {/* {coWorkerRoleForAll.coWorkerRoleForCF == true ? */}
                                  <RACButton
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    loading={count.UHLoader}
                                    disabled={count.UHLoader === true ? true : false}
                                    className={`${classes.ms2} ${classes.floatRight}`}
                                    onClick={() => {
                                      onClickForStatusChange('UH', 'Unhold Inventory')
                                    }}
                                  >
                                    Unhold
                                  </RACButton>
                                  {/* : null} */}
                                </Grid>
                                : null}
                              {validateObject(inventoryInformations.inventoryHeaderObj) && inventoryInformations.inventoryHeaderObj.status == RESERVED ?
                                <Grid className={`${classes.componentRight} ${classes.mt57}`}>
                                  {/* coWorkerRoleForAll.coWorkerRoleForRSVD == true ? */}
                                  <RACButton
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    loading={count.URLoader}
                                    disabled={count.URLoader === true ? true : false}
                                    className={`${classes.ms2} ${classes.floatRight}`}
                                    onClick={() => {
                                      onClickForStatusChange('UR', 'Reserve To Unreserve Inventory')
                                    }}
                                  >
                                    Un Reserved
                                  </RACButton>
                                  {/* : null */}
                                </Grid>
                                : null}
                            </> : null}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  : null}

                {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryHeaderObj.status) && validateObject(inventoryInformations.currentAgreementObj) && (inventoryInformations.inventoryHeaderObj.status == ON_RENT || inventoryInformations.inventoryHeaderObj.status === SERVICE_ON_RENT || inventoryInformations.inventoryHeaderObj.status === LOANER) || inventoryInformations.inventoryHeaderObj.status === PENDING_RENTAL_AGREEMENT ?

                  <>
                    <Grid className={classes.mt4}>
                      <Typography variant="h5" className={classes.m2}>
                        Current Agreement
                      </Typography>
                    </Grid>
                    <Card className={`${classes.m2} ${classes.racCard}`}>
                      <CardContent>
                        <Grid container spacing={4} style={{ marginBottom: '10px' }} className={classes.alignCenter}>
                          <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                            <Typography className={form.formLabel}>Agreement # </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`}><a className={classes.racpadLink} onClick={() => history.push(`/agreement/info/details/${inventoryInformations.currentAgreementObj.customerId}/${inventoryInformations.currentAgreementObj.agreementId}`)}>{validateObject(inventoryInformations.currentAgreementObj.agreementNumber) && inventoryInformations.currentAgreementObj.agreementNumber !== '' ? inventoryInformations.currentAgreementObj.agreementNumber : '-'}</a></Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                            <Typography className={form.formLabel}>Customer </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.currentAgreementObj.customerName) && inventoryInformations.currentAgreementObj.customerName.length > 25 ? inventoryInformations.currentAgreementObj.customerName : ''}>{validateObject(inventoryInformations.currentAgreementObj.customerName) && inventoryInformations.currentAgreementObj.customerName !== "" ? truncString(inventoryInformations.currentAgreementObj.customerName, 25, '...') : '-'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                            <Typography className={form.formLabel}>Open Date </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.currentAgreementObj.openDate) && inventoryInformations.currentAgreementObj.openDate !== "" ? moment(inventoryInformations.currentAgreementObj.openDate).format('MM/DD/YYYY') : '-'}</Typography>
                          </Grid>

                          <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                            <Typography className={form.formLabel}>Rate </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`}>
                              {validateObject(inventoryInformations.currentAgreementObj.rate) && (inventoryInformations.currentAgreementObj.rate !== "0" || inventoryInformations.currentAgreementObj.rate !== "0.00") ?
                                <NumberFormat
                                  thousandsGroupStyle="thousand"
                                  value={roundOff(inventoryInformations.currentAgreementObj.rate, 3).toFixed(2)}
                                  prefix="$ "
                                  className={classes.formLabelValue}
                                  displayType="text"
                                  type="text"
                                  thousandSeparator={true}
                                />
                                : '$ 0.00'}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                            <Typography className={form.formLabel}>Term </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.currentAgreementObj.term) && inventoryInformations.currentAgreementObj.term !== '' ? inventoryInformations.currentAgreementObj.term : '-'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                            <Typography variant="caption" className={form.formLabel}>Total RTO </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`}>
                              {validateObject(inventoryInformations.currentAgreementObj.totalRto) && (inventoryInformations.currentAgreementObj.totalRto !== "0" || inventoryInformations.currentAgreementObj.totalRto !== "0.00") ?
                                <NumberFormat
                                  thousandsGroupStyle="thousand"
                                  value={roundOff(inventoryInformations.currentAgreementObj.totalRto, 3).toFixed(2)}
                                  prefix="$ "
                                  className={classes.formLabelValue}
                                  displayType="text"
                                  type="text"
                                  thousandSeparator={true}
                                /> : '$ 0.00'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                            <Typography variant="caption" className={form.formLabel}>Total Revenue </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`}>
                              {validateObject(inventoryInformations.currentAgreementObj.totalRevenue) && (inventoryInformations.currentAgreementObj.totalRevenue !== "0" || inventoryInformations.currentAgreementObj.totalRevenue !== "0.00") ?
                                <NumberFormat
                                  thousandsGroupStyle="thousand"
                                  value={roundOff(inventoryInformations.currentAgreementObj.totalRevenue, 3).toFixed(2)}
                                  prefix="$ "
                                  className={classes.formLabelValue}
                                  displayType="text"
                                  type="text"
                                  thousandSeparator={true}
                                /> : '$ 0.00'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                            <Typography variant="caption" className={form.formLabel}>MTD Revenue </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`}>
                              {validateObject(inventoryInformations.currentAgreementObj.mtdRevenue) && (inventoryInformations.currentAgreementObj.mtdRevenue !== "0" || inventoryInformations.currentAgreementObj.mtdRevenue !== "0.00") ?
                                <NumberFormat
                                  thousandsGroupStyle="thousand"
                                  value={roundOff(inventoryInformations.currentAgreementObj.mtdRevenue, 3).toFixed(2)}
                                  prefix="$ "
                                  className={classes.formLabelValue}
                                  displayType="text"
                                  type="text"
                                  thousandSeparator={true}
                                /> : '$ 0.00'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                            <Typography variant="caption" className={form.formLabel}>Agreement RV </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`}>
                              {validateObject(inventoryInformations.currentAgreementObj.agreementRv) && (inventoryInformations.currentAgreementObj.agreementRv !== "0" || inventoryInformations.currentAgreementObj.agreementRv !== "0.00") ?
                                <NumberFormat
                                  thousandsGroupStyle="thousand"
                                  value={roundOff(inventoryInformations.currentAgreementObj.agreementRv, 3).toFixed(2)}
                                  prefix="$ "
                                  className={classes.formLabelValue}
                                  displayType="text"
                                  type="text"
                                  thousandSeparator={true}
                                /> : '$ 0.00'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </>
                  : null}



                {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryHeaderObj.status) && validateObject(inventoryInformations.chargedOffObj) && (inventoryInformations.inventoryHeaderObj.status === CHARGED_OFF || inventoryInformations.inventoryHeaderObj.status === PENDING_CHARGE_OFF) ?

                  <>
                    <Grid className={classes.mt4}>
                      <Typography variant="h5" className={classes.m2}>
                        Charged Off
                      </Typography>
                    </Grid>
                    <Card className={`${classes.m2} ${classes.racCard}`}>
                      <CardContent>
                        <Grid container spacing={1} className={classes.alignCenter}>

                          {validateObject(inventoryInformations.chargedOffObj.reason) && inventoryInformations.chargedOffObj.reason == "Payout" ?

                            <React.Fragment>
                              <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                                <Typography className={form.formLabel}>Agreement # </Typography>
                                {/* <Typography className={`${form.formValue} ${classes.mt2}`} style={{ color: validateObject(inventoryInformations.chargedOffObj.agreement) ? '#0d6efd' : '' }}>
                                  {validateObject(inventoryInformations.chargedOffObj.agreement) ? inventoryInformations.chargedOffObj.agreement : 'N/A'}
                                </Typography> */}
                                <Typography className={`${form.formValue} ${classes.mt2}`} style={{ color: validateObject(inventoryInformations.chargedOffObj.agreement) ? '#0d6efd' : '' }}><a className={classes.racpadLink} onClick={() => history.push(`/agreement/info/details/${inventoryInformations.chargedOffObj.customerId}/${inventoryInformations.chargedOffObj.agreementId}`)}>{validateObject(inventoryInformations.chargedOffObj.agreement) && inventoryInformations.chargedOffObj.agreement !== '' ? inventoryInformations.chargedOffObj.agreement : '-'}</a></Typography>

                              </Grid>

                              <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                                <Typography className={form.formLabel}>Receipt # </Typography>
                                <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.chargedOffObj.receipt) ? inventoryInformations.chargedOffObj.receipt : 'N/A'}</Typography>
                              </Grid>

                              <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                                <Typography className={form.formLabel}>Customer </Typography>
                                <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.chargedOffObj.customer) && inventoryInformations.chargedOffObj.customer.length > 25 ? inventoryInformations.chargedOffObj.customer : ''} >{validateObject(inventoryInformations.chargedOffObj.customer) && inventoryInformations.chargedOffObj.customer !== "" ? truncString(inventoryInformations.chargedOffObj.customer, 25, '...') : 'N/A'}</Typography>
                              </Grid>
                            </React.Fragment>


                            :
                            <React.Fragment>
                              <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                                <Typography className={form.formLabel}>Agreement # </Typography>
                                <Typography className={`${form.formValue} ${classes.mt2}`} style={{ color: validateObject(inventoryInformations.chargedOffObj.agreement) ? '#0d6efd' : '' }}>
                                  {/* {"-"} */}
                                  {validateObject(inventoryInformations.chargedOffObj.agreement) ? inventoryInformations.chargedOffObj.agreement : 'N/A'}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                                <Typography className={form.formLabel}>Receipt # </Typography>
                                <Typography className={`${form.formValue} ${classes.mt2}`}>
                                  {validateObject(inventoryInformations.chargedOffObj.receipt) ? inventoryInformations.chargedOffObj.receipt : 'N/A'}
                                  {/* {"-"} */}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                                <Typography className={form.formLabel}>Customer </Typography>
                                <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.chargedOffObj.customer) && inventoryInformations.chargedOffObj.customer.length > 25 ? inventoryInformations.chargedOffObj.customer : ''} >
                                  {/* {"-"} */}
                                  {validateObject(inventoryInformations.chargedOffObj.customer) && inventoryInformations.chargedOffObj.customer !== "" ? truncString(inventoryInformations.chargedOffObj.customer, 25, '...') : 'N/A'}
                                </Typography>
                              </Grid>
                            </React.Fragment>
                          }

                          <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                            <Typography className={form.formLabel}>Reason </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.chargedOffObj.reason) && inventoryInformations.chargedOffObj.reason.length > 25 ? inventoryInformations.chargedOffObj.reason : ''} >{validateObject(inventoryInformations.chargedOffObj.reason) ? truncString(inventoryInformations.chargedOffObj.reason, 25, '...') : '-'}</Typography>
                          </Grid>

                          <Grid item xs={12} sm={4} md={4} className={classes.fiveColumns}>
                            <Typography className={form.formLabel}>Date </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.chargedOffObj.date) ? moment(inventoryInformations.chargedOffObj.date).format('MM/DD/YYYY') : '-'}</Typography>
                          </Grid>


                        </Grid>
                      </CardContent>
                    </Card>
                  </>

                  : null}

                {inventoryInformations.inventoryHeaderObj.statusRefCode == 'CO' && !NOT_RECOVER_CHARGEOFF_REASON_REFCODES.includes(inventoryInformations.inventoryInformationObj.statusReasonType) && recoverFeature == '1' ?
                  <>
                    <Grid className={classes.mt4}>
                      <Typography variant="h5" className={classes.m2}>
                        Recovery
                      </Typography>
                    </Grid>
                    <Card className={`${classes.m2} ${classes.racCard}`}>
                      <CardContent>
                        <Grid container spacing={1} className={classes.alignCenter}>
                          <Grid item xs={12} sm={4} md={4} className={`${classes.alignCenter} ${classes.fiveColumns} `}>

                            <Typography className={form.formLabel} >Recovery Date </Typography>
                            <RACDatePicker label='' disabled={true} />
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} className={`${classes.alignCenter} ${classes.fiveColumns}`}>
                            <Typography className={form.formLabel}>RV at Recovery </Typography>
                            <RACTextbox value={'0'}
                              inputLabelClassname={classes.formLabel}
                              disabled={true} />

                          </Grid>
                          <Grid item xs={12} sm={4} md={4} className={`${classes.alignCenter} ${classes.fiveColumns}`} style={{ marginTop: '18px' }}>

                            <RACButton
                              variant="contained"
                              color="primary"
                              size="small"
                              className={`${classes.mx1} ${classes.pb1} ${classes.pt1}`}
                              onClick={() => setRecoverSecondLvlAuth(true)}
                            >
                              Recover
                            </RACButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </> :
                  null}
                {inventoryInformations?.recoveryObj?.isRecoveryEnabled && recoverFeature == '1' ?
                  <>
                    <Grid className={classes.mt4}>
                      <Typography variant="h5" className={classes.m2}>
                        Recovery
                      </Typography>
                    </Grid>
                    <Card className={`${classes.m2} ${classes.racCard}`}>
                      <CardContent>
                        <Grid container spacing={1} className={classes.alignCenter}>
                          <Grid item xs={12} sm={4} md={4} className={`${classes.alignCenter} ${classes.fiveColumns} `}>

                            <Typography className={form.formLabel} >Recovery Date </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`}>{inventoryInformations?.recoveryObj?.recoveryDate} </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} className={`${classes.alignCenter} ${classes.fiveColumns}`}>
                            <Typography className={form.formLabel}>RV at Recovery </Typography>
                            <Typography className={`${form.formValue} ${classes.mt2}`}>{inventoryInformations?.recoveryObj?.rvAtRecovery}</Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </> :
                  null}
              </Grid>
            </Grid>


            {/* --- Footer Code Starts Here ---  */}
            <Grid container style={{ zIndex: '0' }} className={classes.fixedFooter}>
              <Grid className={classes.floatLeft}>
                <RACButton variant="outlined" color="primary" className={classes.ms2} onClick={() => onClickCancelBtn()} >Cancel</RACButton>
              </Grid>
              {inventoryInformations.inventoryHeaderObj.storeNumber === storeNo ?
                <Grid className={classes.floatRight}>
                  <RACButton
                    variant="contained"
                    color="primary"
                    className={classes.mr2}
                    loading={(infoPrint === true) ? true : false}
                    disabled={infoPrint}
                    onClick={() => { !printFailed ? printHTML(document.getElementById('InventoryPrintHTML')) : setPrintFailOpen(true) }}
                  >
                    Print
                  </RACButton>
                  {/* <>
                  <ReactToPrint
                    trigger={() =>
                      <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.mr2}
                      >
                        Print
                      </RACButton>}
                    content={() => componentRef.current}
                    documentTitle="Inventory Item Information"
                  />

                </> */}
                  <RACButton
                    variant="contained"
                    color="primary"
                    loading={savebtnLoader}
                    disabled={serialNumber.length > 0 && enableSaveBtnForAuditMsg ? savebtnLoader === false ? false : true : true}
                    onClick={() => onClickSaveBtn()}
                  >
                    Save
                  </RACButton>
                </Grid> : null}
            </Grid>
            <Grid>
              {/* --- Footer Code Ends Here --- */}

              {secondLvlAuth == true ? (
                <SecondFactor
                  setTwoFactorCancelClick={() => { setsecondLvlAuth(false), setchargeOffState('Select') }}
                  setTwoFactorCompleted={(e: any) => secondLvlAuthRes(e)}
                  moduleName={moduleName}
                  CurrentRole={currentCWRole}
                  employeeID={employeeId}
                ></SecondFactor>
              ) : null}

              {recoverSecondLvlAuth == true ? (
                <SecondFactor
                  setTwoFactorCancelClick={() => { setRecoverSecondLvlAuth(false) }}
                  setTwoFactorCompleted={(e: any) => setpopUpStateForAll({ ...popUpStateForAll, recoverpopup: true })}
                  moduleName={'InventoryRecovery'}
                  CurrentRole={currentCWRole}
                  employeeID={employeeId}
                ></SecondFactor>
              ) : null}

              <RACModalCard
                isOpen={popUpStateForAll.popup}
                maxWidth="xs"
                title="Inventory Transfer"
                onClose={() => inventoryTransferClose()}
                closeIcon={true}
                className={classes.modalWidth}
                borderRadius={'20px !important'}
                /* eslint-disable react/no-children-prop */
                children={inventoryTransferPopUp()}
              />

              <RACModalCard
                borderRadius="20px"
                isOpen={transferValidatePack}
                maxWidth="xs"
                onClose={() => setTransferValidatePack(false)}
                // eslint-disable-next-line react/no-children-prop
                children={TransferpackageValidatePopUp()}
              />

              <RACModalCard
                isOpen={popUpStateForAll.transferSuccessPopup}
                maxWidth="xs"
                onClose={() => transferInventoryStatusUpdate()}
                closeIcon={true}
                className={classes.saveModalWidth}
                borderRadius={'16px !important'}
                /* eslint-disable react/no-children-prop */
                children={inventoryTransferSavePopUp()}
              />

              <RACModalCard
                isOpen={popUpStateForAll.chargeoffpopup}
                maxWidth="xs"
                onClose={() => {
                  setpopUpStateForAll({
                    ...popUpStateForAll,
                    chargeoffpopup: false
                  })
                  setchargeOffState('Select')
                }}
                closeIcon={true}
                className={classes.chargeOffModalWidth}
                borderRadius={'16px !important'}
                /* eslint-disable react/no-children-prop */
                children={chargeOffPopUp()}
              />

              <RACModalCard
                isOpen={popUpStateForAll.chargeoffConfirmpopup}
                maxWidth="xs"
                onClose={() =>
                  setpopUpStateForAll({
                    ...popUpStateForAll,
                    chargeoffConfirmpopup: false
                  })
                }
                closeIcon={true}
                className={classes.chargeOffModalWidth1}
                borderRadius={'16px !important'}
                /* eslint-disable react/no-children-prop */
                children={chargeOffConfirm()}
              />

              <RACModalCard
                isOpen={popUpStateForAll.transferPrintPopup}
                onClose={() => transferInventorySaveBtn()}
                maxWidth="xs"
                // closeIcon={true}
                borderRadius="20px"
                title="Inventory Transfer"
                // eslint-disable-next-line react/no-children-prop
                children={PrintPopupOpen()}
              />

              <RACModalCard
                isOpen={popUpStateForAll.chargeoffCancelpopup}
                maxWidth="xs"
                onClose={() =>
                  setpopUpStateForAll({
                    ...popUpStateForAll,
                    chargeoffCancelpopup: false
                  })
                }
                closeIcon={true}
                className={classes.chargeOffModalWidth1}
                borderRadius={'16px !important'}
                /* eslint-disable react/no-children-prop */
                children={chargeOffCancel()}
              />

              <RACModalCard
                isOpen={popUpStateForAll.loanerpopup}
                maxWidth="xs"
                onClose={() =>
                  setpopUpStateForAll({
                    ...popUpStateForAll,
                    loanerpopup: false
                  })
                }
                closeIcon={true}
                borderRadius={'16px !important'}
                className={classes.chargeOffModalWidth2}
                /* eslint-disable react/no-children-prop */
                children={loanerPopUp()}
              />

              <RACModalCard
                isOpen={popUpStateForAll.recoverpopup}
                maxWidth="xs"
                onClose={() => {
                  setpopUpStateForAll({
                    ...popUpStateForAll,
                    recoverpopup: false
                  })
                  setrecoverReasonState('Select')
                }}
                closeIcon={true}
                className={classes.chargeOffModalWidth}
                borderRadius={'16px !important'}
                /* eslint-disable react/no-children-prop */
                children={recoverPopUp()}
              />

              <RACModalCard
                isOpen={popUpStateForAll.savePopupState}
                maxWidth="xs"
                onClose={() => closeSavePopUp()
                }
                closeIcon={true}
                /* eslint-disable react/no-children-prop */
                children={saveSuccessfullPopUp()}
              />

              <RACModalCard
                borderRadius="20px"
                isOpen={popUpStateForAll.somethingWentWrongPopUpState}
                maxWidth="xs"
                // closeIcon={true}
                onClose={() => { setpopUpStateForAll({ ...popUpStateForAll, somethingWentWrongPopUpState: false }); seterrMsgTxt('') }}
                // eslint-disable-next-line react/no-children-prop
                children={SomethingWentWrongPopUp()}
              />

              <RACModalCard
                borderRadius="20px"
                isOpen={printFailOpen}
                maxWidth="xs"
                closeIcon={false}
                // eslint-disable-next-line react/no-children-prop
                children={PrintFailPopup()}
              />

              <div className={classes.hide}>
                <PrintInfo inventoryInfoRes={inventoryInformations} inventoryDetail={inventoryDetails} />
                {/* <div id="transferReceiveFormInnerHtml">
                  <div style={{ float: 'left', width: '94%', padding: '3%' }}>

                    <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
                      <h2 style={{ float: 'left', margin: '0px' }}>Transfer Received Form</h2>
                      <div style={{ float: 'right' }}>{moment().format('ddd,D MMM YYYY, hh:mm A')}</div>
                    </div>

                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                      <label style={{ float: 'right' }}>Page: 1</label>
                    </div>

                    <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
                      <label style={{ margin: '0px' }}>Transferring Store: {validateObject(storeNo) ? storeNo : '-'}</label>
                    </div>
                    <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
                      <label style={{ margin: '0px' }}>Receiving Store: {validateObject(transferState) && transferState != 'Select' ? transferState : validateObject(transfer2Store) && transfer2Store != '' ? transfer2Store : ''}</label>
                    </div>

                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                          <tr>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Item</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Model</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Vendor</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Brand</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>MFG Serial #</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryHeaderObj.itemNumber) ? inventoryInformations.inventoryHeaderObj.itemNumber : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.modelNumber) ? inventoryInformations.inventoryInformationObj.modelNumber : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj) && validateObject(inventoryInformations.depreciationEstimatorObj.vendor) ? inventoryInformations.depreciationEstimatorObj.vendor : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj) && validateObject(inventoryInformations.depreciationEstimatorObj.brand) ? inventoryInformations.depreciationEstimatorObj.brand : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>-</td>
                          </tr>
                          <tr />
                        </tbody>
                      </table>
                    </div>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center', marginBottom: '70px', marginTop: '70px' }}>
                      <div style={{ width: '100%', fontSize: '25px' }}>I Am Sold!</div>
                      <div style={{ width: '100%', fontSize: '25px' }}>Please ask to see another</div>
                      <div style={{ width: '100%', fontSize: '25px' }}>One just like me!</div>
                    </div>
                    <div style={{ width: '100%', float: 'left' }}>
                      <div style={{ width: '50%', float: 'left' }}>
                        <h4>Delivered By</h4>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                        </div>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                        </div>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Signature</div>
                        </div>
                      </div>

                      <div style={{ width: '50%', float: 'left' }}>
                        <h4>Received By</h4>
                        <div style={{ width: '70%', float: 'left', marginBottom: '30px' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                        </div>
                        <div style={{ width: '30%', float: 'left', marginBottom: '30px' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '70%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Date</div>
                        </div>

                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                        </div>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Signature</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              {/* Printing docs */}
              {/* Transfer Out Form start */}
              <div className={classes.hide}>
                <div id="transferOutFormInnerHtml">
                  <div style={{ float: 'left', width: '94%', padding: '3%' }}>

                    <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
                      <h2 style={{ float: 'left', margin: '0px' }}>Transfer Out Form</h2>
                      <div style={{ float: 'right' }}>{moment().format('ddd,D MMM YYYY, hh:mm A')}</div>
                    </div>

                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                      <label style={{ float: 'right' }}>Page: 1</label>
                    </div>

                    <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
                      <label style={{ margin: '0px' }}>Transferring Store: {validateObject(storeNo) ? storeNo : '-'}</label>
                    </div>
                    <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
                      <label style={{ margin: '0px' }}>Receiving Store: {validateObject(receivingStore) ? receivingStore : ' - '}</label>
                      {/* <label style={{ margin: '0px' }}>Receiving Store: {validateObject(transferState) && transferState != 'Select' ? transferState : validateObject(transfer2Store) && transfer2Store != '' ? transfer2Store : ''}</label> */}
                    </div>

                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                          <tr>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Item</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Model</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Vendor</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Brand</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>MFG Serial #</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryHeaderObj.itemNumber) ? inventoryInformations.inventoryHeaderObj.itemNumber : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.modelNumber) ? inventoryInformations.inventoryInformationObj.modelNumber : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryDetails.depreciationEstimatorObj) && validateObject(inventoryDetails.depreciationEstimatorObj.vendor) ? inventoryDetails.depreciationEstimatorObj.vendor : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryDetails.depreciationEstimatorObj) && validateObject(inventoryDetails.depreciationEstimatorObj.brand) ? inventoryDetails.depreciationEstimatorObj.brand : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.serialNumber) ? inventoryInformations.inventoryInformationObj.serialNumber : '-'}</td>
                          </tr>
                          <tr />
                        </tbody>
                      </table>
                    </div>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center', marginBottom: '70px', marginTop: '70px' }}>
                      <div style={{ width: '100%', fontSize: '25px' }}>I Am Sold!</div>
                      <div style={{ width: '100%', fontSize: '25px' }}>Please ask to see another</div>
                      <div style={{ width: '100%', fontSize: '25px' }}>One just like me!</div>
                    </div>
                    <div style={{ width: '100%', float: 'left' }}>
                      <div style={{ width: '50%', float: 'left' }}>
                        <h4>Delivered By</h4>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                        </div>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                        </div>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Signature</div>
                        </div>
                      </div>

                      <div style={{ width: '50%', float: 'left' }}>
                        <h4>Received By</h4>
                        <div style={{ width: '70%', float: 'left', marginBottom: '30px' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                        </div>
                        <div style={{ width: '30%', float: 'left', marginBottom: '30px' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '70%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Date</div>
                        </div>

                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                        </div>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Signature</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Transfer Out Form end*/}
              <div className={classes.hide}>
                <div id="InventoryPrintHTML">
                  <div style={{ width: '96%', margin: '2%', float: 'left' }}>
                    <div style={{ width: '100%', float: 'left', marginBottom: '6px' }}>
                      <h2 style={{ float: 'left', margin: '0px' }}>Inventory Item Information</h2>
                      <div style={{ float: 'right', marginTop: '5px', fontStyle: 'italic' }}>{moment().format('ddd,D MMM YYYY, hh:mm A')}</div>
                    </div>

                    <div style={{ width: '100%', float: 'left', marginBottom: '4px' }}>
                      <div style={{ width: '20%', float: 'left' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Rent-A-Center</span>
                        <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}({storeNo})</span>
                      </div>
                      <div style={{ width: '30%', float: 'left' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Item #:</span>
                        <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}{inventoryNum}</span>
                      </div>
                      <div style={{ width: '50%', float: 'left' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Description:</span>
                        <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}{headerContext.description}</span>
                      </div>
                    </div>

                    {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryInformationObj) ?
                      <>


                        <div style={{ width: '100%', float: 'left' }}>
                          <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>General Information</h3>

                          <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Status</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryHeaderObj.status) ? inventoryInformations.inventoryHeaderObj.status : '-'}</div>
                              </span>
                            </div>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Current Rate</div>
                                <div style={{ fontSize: '14px' }}>
                                  {validateObject(inventoryInformations.inventoryInformationObj.currentRate) && (inventoryInformations.inventoryInformationObj.currentRate !== "0" || inventoryInformations.inventoryInformationObj.currentRate !== "0.00") ?
                                    <NumberFormat
                                      thousandsGroupStyle="thousand"
                                      value={roundOff(inventoryInformations.inventoryInformationObj.currentRate, 3).toFixed(2)}
                                      prefix="$ "
                                      className={classes.formLabelValue}
                                      displayType="text"
                                      type="text"
                                      thousandSeparator={true}
                                    /> : "$ 0.00"}</div>
                              </span>
                            </div>
                            {/* <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Sub Status</div>
                                <div style={{ fontSize: '14px' }}>{ }</div>
                              </span>
                            </div> */}
                          </div>

                          <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Current Term</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj.currentTerm) && inventoryInformations.inventoryInformationObj.currentTerm !== 0 ? inventoryInformations.inventoryInformationObj.currentTerm : '0'}</div>
                              </span>
                            </div>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Department</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj.department) ? inventoryInformations.inventoryInformationObj.department : '-'}</div>
                              </span>
                            </div>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Purchase Date</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj) && validateObject(inventoryInformations.depreciationEstimatorObj.purchaseDate) ? moment(inventoryInformations.depreciationEstimatorObj.purchaseDate).format('MM/DD/YYYY') : '-'}</div>
                              </span>
                            </div>
                          </div>

                          <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Sub Department</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj.subDepartment) ? inventoryInformations.inventoryInformationObj.subDepartment : '-'}</div>
                              </span>
                            </div>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Purchase Cost</div>
                                <div style={{ fontSize: '14px' }}>{
                                  validateObject(inventoryInformations.depreciationEstimatorObj) && validateObject(inventoryInformations.depreciationEstimatorObj.purchaseCost) && (inventoryInformations.depreciationEstimatorObj.purchaseCost !== "0" || inventoryInformations.depreciationEstimatorObj.purchaseCost !== "0.00") ?
                                    <NumberFormat
                                      thousandsGroupStyle="thousand"
                                      value={roundOff(inventoryInformations.depreciationEstimatorObj.purchaseCost, 3).toFixed(2)}
                                      prefix="$ "
                                      className={classes.formLabelValue}
                                      displayType="text"
                                      type="text"
                                      thousandSeparator={true}
                                    /> : '$ 0.00'}</div>
                              </span>
                            </div>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Bracket</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj.bracket) ? inventoryInformations.inventoryInformationObj.bracket : '-'}</div>
                              </span>
                            </div>
                          </div>

                          <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Total Revenue</div>
                                <div style={{ fontSize: '14px' }}>
                                  {validateObject(inventoryInformations.currentAgreementObj) && validateObject(inventoryInformations.currentAgreementObj.totalRevenue) && (inventoryInformations.currentAgreementObj.totalRevenue !== "0" || inventoryInformations.currentAgreementObj.totalRevenue !== "0.00") ?
                                    <NumberFormat
                                      thousandsGroupStyle="thousand"
                                      value={roundOff(inventoryInformations.currentAgreementObj.totalRevenue, 3).toFixed(2)}
                                      prefix="$ "
                                      className={classes.formLabelValue}
                                      displayType="text"
                                      type="text"
                                      thousandSeparator={true}
                                    /> : '$ 0.00'}</div>
                              </span>
                            </div>

                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Brand</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj.brand) ? inventoryInformations.inventoryInformationObj.brand : '-'}</div>
                              </span>
                            </div>

                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Revenue Turns</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj) && validateObject(inventoryInformations.depreciationEstimatorObj.revenueTurns) && inventoryInformations.depreciationEstimatorObj.revenueTurns !== 0 ? inventoryInformations.depreciationEstimatorObj.revenueTurns : '0.00'}</div>
                              </span>
                            </div>
                          </div>

                          <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Model #</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj.modelNumber) ? inventoryInformations.inventoryInformationObj.modelNumber : '-'}</div>
                              </span>
                            </div>

                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Remaining Value</div>
                                <div style={{ fontSize: '14px' }}>
                                  {validateObject(inventoryInformations.depreciationEstimatorObj) && validateObject(inventoryInformations.depreciationEstimatorObj.remainingValue) && (inventoryInformations.depreciationEstimatorObj.remainingValue !== "0" || inventoryInformations.depreciationEstimatorObj.remainingValue !== "0.00") ?
                                    <NumberFormat
                                      thousandsGroupStyle="thousand"
                                      value={roundOff(inventoryInformations.depreciationEstimatorObj.remainingValue, 3).toFixed(2)}
                                      prefix="$ "
                                      className={classes.formLabelValue}
                                      displayType="text"
                                      type="text"
                                      thousandSeparator={true}
                                    />
                                    : '$ 0.00'}
                                </div>
                              </span>
                            </div>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Serial #</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj.serialNumber) ? inventoryInformations.inventoryInformationObj.serialNumber : '-'}</div>
                              </span>
                            </div>
                          </div>

                        </div>

                        <div style={{ width: '100%', float: 'left' }}>
                          <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>Audit Message</h3>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span style={{ fontSize: '14px' }}>{auditMessage}</span>
                          </div>
                        </div>
                      </>
                      : null}

                    {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.currentAgreementObj) && validateObject(inventoryInformations.inventoryHeaderObj.status) && (inventoryInformations.inventoryHeaderObj.status == ON_RENT || inventoryInformations.inventoryHeaderObj.status === SERVICE_ON_RENT || inventoryInformations.inventoryHeaderObj.status === LOANER) || inventoryInformations.inventoryHeaderObj.status === PENDING_RENTAL_AGREEMENT ?
                      <div style={{ width: '100%', float: 'left' }}>
                        <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>Current Agreement</h3>

                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Agreement #</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.currentAgreementObj.agreementNumber) ? inventoryInformations.currentAgreementObj.agreementNumber : '-'}</div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Total RTO</div>
                              <div style={{ fontSize: '14px' }}>
                                {validateObject(inventoryInformations.currentAgreementObj.totalRto) && (inventoryInformations.currentAgreementObj.totalRto !== "0" || inventoryInformations.currentAgreementObj.totalRto !== "0.00") ?
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={roundOff(inventoryInformations.currentAgreementObj.totalRto, 3).toFixed(2)}
                                    prefix="$ "
                                    className={classes.formLabelValue}
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                  /> : '$ 0.00'}
                              </div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Customer</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.currentAgreementObj.customerName) ? inventoryInformations.currentAgreementObj.customerName : '-'}</div>
                            </span>
                          </div>
                        </div>

                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Total Revenue</div>
                              <div style={{ fontSize: '14px' }}>
                                {validateObject(inventoryInformations.currentAgreementObj.totalRevenue) && (inventoryInformations.currentAgreementObj.totalRevenue !== "0" || inventoryInformations.currentAgreementObj.totalRevenue !== "0.00") ?
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={roundOff(inventoryInformations.currentAgreementObj.totalRevenue, 3).toFixed(2)}
                                    prefix="$ "
                                    className={classes.formLabelValue}
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                  /> : '$ 0.00'}
                              </div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Open Date</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.currentAgreementObj.openDate) ? moment(inventoryInformations.currentAgreementObj.openDate).format('MM/DD/YYYY') : '-'}</div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>MTD Revenue</div>
                              <div style={{ fontSize: '14px' }}>
                                {validateObject(inventoryInformations.currentAgreementObj.mtdRevenue) && (inventoryInformations.currentAgreementObj.mtdRevenue !== "0" || inventoryInformations.currentAgreementObj.mtdRevenue !== "0.00") ?
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={roundOff(inventoryInformations.currentAgreementObj.mtdRevenue, 3).toFixed(2)}
                                    prefix="$ "
                                    className={classes.formLabelValue}
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                  /> : '$ 0.00'}
                              </div>
                            </span>
                          </div>
                        </div>

                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Rate</div>
                              <div style={{ fontSize: '14px' }}>
                                {validateObject(inventoryInformations.currentAgreementObj.rate) && (inventoryInformations.currentAgreementObj.rate !== "0" || inventoryInformations.currentAgreementObj.rate !== "0.00") ?
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={roundOff(inventoryInformations.currentAgreementObj.rate, 3).toFixed(2)}
                                    prefix="$ "
                                    className={classes.formLabelValue}
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                  />
                                  : '$ 0.00'}

                              </div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Agreement RV</div>
                              <div style={{ fontSize: '14px' }}>
                                {validateObject(inventoryInformations.currentAgreementObj.agreementRv) && (inventoryInformations.currentAgreementObj.agreementRv !== "0" || inventoryInformations.currentAgreementObj.agreementRv !== "0.00") ?
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={roundOff(inventoryInformations.currentAgreementObj.agreementRv, 3).toFixed(2)}
                                    prefix="$ "
                                    className={classes.formLabelValue}
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                  /> : '$ 0.00'}
                              </div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Term</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.currentAgreementObj.term) && inventoryInformations.currentAgreementObj.term !== 0 ? inventoryInformations.currentAgreementObj.term : '0'}</div>
                            </span>
                          </div>
                        </div>
                      </div> : null}

                    {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.depreciationEstimatorObj) ?
                      <div style={{ width: '100%', float: 'left' }}>
                        <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>Depreciation Estimator</h3>
                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>PO #</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.po) ? inventoryInformations.depreciationEstimatorObj.po.replace(/[^0-9]/g, "") : '-'}</div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Purchase Date</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.purchaseDate) ? moment(inventoryInformations.depreciationEstimatorObj.purchaseDate).format('MM/DD/YYYY') : '-'}</div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Brand</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.brand) ? inventoryInformations.depreciationEstimatorObj.brand : '-'}</div>
                            </span>
                          </div>
                        </div>

                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Purchase Cost</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.purchaseCost) && (inventoryInformations.depreciationEstimatorObj.purchaseCost !== "0" || inventoryInformations.depreciationEstimatorObj.purchaseCost !== "0.00") ?
                                <NumberFormat
                                  thousandsGroupStyle="thousand"
                                  value={roundOff(inventoryInformations.depreciationEstimatorObj.purchaseCost, 3).toFixed(2)}
                                  prefix="$ "
                                  className={classes.formLabelValue}
                                  displayType="text"
                                  type="text"
                                  thousandSeparator={true}
                                /> : '$ 0.00'}
                              </div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Model #</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.modelNumber) ? inventoryInformations.depreciationEstimatorObj.modelNumber : '-'}</div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Vendor</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.vendor) ? inventoryInformations.depreciationEstimatorObj.vendor : '-'}</div>
                            </span>
                          </div>
                        </div>

                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>

                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Serial #</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.serialNumber) ? inventoryInformations.depreciationEstimatorObj.serialNumber : '-'}</div>
                            </span>
                          </div>
                        </div>
                      </div> : null}

                    {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.gaapDepreciationObj) ?
                      <div style={{ width: '100%', float: 'left' }}>
                        <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>GAAP Depreciation</h3>
                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>

                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Depreciation Method</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.gaapDepreciationObj.depreciationMethod) && (inventoryInformations.gaapDepreciationObj.depreciationMethod !== "0" || inventoryInformations.gaapDepreciationObj.depreciationMethod !== "0.00") ? inventoryInformations.gaapDepreciationObj.depreciationMethod : "Not Available"}</div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Depreciaton Percentage</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.gaapDepreciationObj.depreciationPercentage) && (inventoryInformations.gaapDepreciationObj.depreciationPercentage !== "0" || inventoryInformations.gaapDepreciationObj.depreciationPercentage !== "0.00") ? roundOff((inventoryInformations.gaapDepreciationObj?.depreciationPercentage), 3).toFixed(2) + ' %' : "Not Available"}</div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Gross Profit Per Payment</div>
                              <div style={{ fontSize: '14px' }}>
                                {validateObject(inventoryInformations.gaapDepreciationObj.grossProfitPerPayment) && (inventoryInformations.gaapDepreciationObj.grossProfitPerPayment !== "0" || inventoryInformations.gaapDepreciationObj.grossProfitPerPayment !== "0.00") ?
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={roundOff(inventoryInformations.gaapDepreciationObj.grossProfitPerPayment, 3).toFixed(2)}
                                    prefix="$ "
                                    className={classes.formLabelValue}
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                  /> : "Not Available"}
                              </div>
                            </span>
                          </div>
                        </div>

                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Turns</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.gaapDepreciationObj.turns) && (inventoryInformations.gaapDepreciationObj.turns !== "0" || inventoryInformations.gaapDepreciationObj.turns !== "0.00") ? roundOff(inventoryInformations.gaapDepreciationObj.turns, 3).toFixed(2) : "Not Available"}</div>
                            </span>
                          </div>
                        </div>
                      </div> : null}

                    {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.idleObj) && validateObject(inventoryInformations.inventoryHeaderObj.status) && (inventoryInformations.inventoryHeaderObj.status === RENT_READY || inventoryInformations.inventoryHeaderObj.status === NOT_RENT_READY || inventoryInformations.inventoryHeaderObj.status === PENDING_CHARGE_OFF || inventoryInformations.inventoryHeaderObj.status === PENDING_LOANER_AGREEMENT || inventoryInformations.inventoryHeaderObj.status === PENDING_RENTAL_AGREEMENT ||
                      inventoryInformations.inventoryHeaderObj.status === HOLD || inventoryInformations.inventoryHeaderObj.status === TRANSFER_IN_PROGRESS || inventoryInformations.inventoryHeaderObj.status === SERVICE_IDLE || inventoryInformations.inventoryHeaderObj.status === RESERVED) ?
                      <>
                        <div style={{ width: '100%', float: 'left' }}>
                          <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>Idle</h3>
                          <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Idle Since</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.idleSince) ? moment(inventoryInformations.idleObj.idleSince).format('MM/DD/YYYY') : '-'}</div>
                              </span>
                            </div>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}># Times 90 Days Idle</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.times90DaysIdle) ? inventoryInformations.idleObj.times90DaysIdle : '-'}</div>
                              </span>
                            </div>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Days Idle</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.daysIdle) && inventoryInformations.idleObj.daysIdle !== 0 ? inventoryInformations.idleObj.daysIdle : '0'}</div>
                              </span>
                            </div>
                          </div>

                          <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Total Days on Rent</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.totalDaysOnRent) && inventoryInformations.idleObj.totalDaysOnRent !== 0 ? inventoryInformations.idleObj.totalDaysOnRent : '0'}</div>
                              </span>
                            </div>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}># Days Idle in Current Store</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.daysIdleInCurrentStore) ? inventoryInformations.idleObj.daysIdleInCurrentStore : '-'}</div>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                          <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>Store #</h3>
                          <div style={{ width: '100%', float: 'left' }}>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Physical Store</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.physicalStore) ? inventoryInformations.idleObj.physicalStore : '-'}</div>
                              </span>
                            </div>
                            <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                              <span>
                                <div style={{ fontSize: '14px' }}>Financial Store</div>
                                <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.financialStore) ? inventoryInformations.idleObj.financialStore : '-'}</div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                      : null}

                    {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.chargedOffObj) && validateObject(inventoryInformations.inventoryHeaderObj.status) && (inventoryInformations.inventoryHeaderObj.status === CHARGED_OFF || inventoryInformations.inventoryHeaderObj.status === PENDING_CHARGE_OFF) ?
                      <div style={{ width: '100%', float: 'left' }}>
                        <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>Charged Off</h3>
                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Agr #</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.chargedOffObj.agreement) ? inventoryInformations.chargedOffObj.agreement : '-'}</div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Reason</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.chargedOffObj.reason) ? inventoryInformations.chargedOffObj.reason : '-'}</div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Receipt #</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.chargedOffObj.receipt) ? inventoryInformations.chargedOffObj.receipt : '-'}</div>
                            </span>
                          </div>
                        </div>

                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Date</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.chargedOffObj.date) ? moment(inventoryInformations.chargedOffObj.date).format('MM/DD/YYYY') : '-'}</div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Customer</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.currentAgreementObj) && validateObject(inventoryInformations.currentAgreementObj.customerName) ? inventoryInformations.currentAgreementObj.customerName : '-'}</div>
                            </span>
                          </div>
                        </div>
                      </div>
                      : null}
                    {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.recoveryObj) && validateObject(inventoryInformations.inventoryHeaderObj.status) && (inventoryInformations.inventoryHeaderObj.status === CHARGED_OFF || inventoryInformations.inventoryHeaderObj.status === PENDING_CHARGE_OFF) ?
                      <div style={{ width: '100%', float: 'left' }}>
                        <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>Recovery</h3>
                        <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>Recovery Date</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.recoveryObj.recoveryDate) && inventoryInformations.recoveryObj.recoveryDate !== "" ? moment(inventoryInformations.recoveryObj.recoveryDate).format('MM/DD/YYYY') : '-'}</div>
                            </span>
                          </div>
                          <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                            <span>
                              <div style={{ fontSize: '14px' }}>RV at Recovery</div>
                              <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.recoveryObj.rvAtRecovery) && inventoryInformations.recoveryObj.rvAtRecovery !== 0 ? inventoryInformations.recoveryObj.rvAtRecovery : 0}</div>
                            </span>
                          </div>
                        </div>
                      </div> : null}

                  </div>
                </div>
              </div>

              <div className={classes.hide}>
                <div id="printRentReadyInlineHtml">
                  <div style={{ float: 'left', width: '96%', padding: '2%' }}>
                    <div style={{ float: 'left', width: '100%' }}>
                      <h5 style={{ float: 'left', marginBottom: '0px', marginTop: '0px' }}>Ready Rent Form</h5>
                    </div>
                    <div style={{ float: 'left', width: '100%' }}>
                      <h1 style={{ float: 'left', fontSize: '50px' }}>
                        {validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.currentRate) ?
                          <NumberFormat
                            thousandsGroupStyle="thousand"
                            value={roundOff(inventoryInformations.inventoryInformationObj.currentRate, 3).toFixed(2)}
                            prefix="$ "
                            className={classes.formLabelValue}
                            displayType="text"
                            type="text"
                            thousandSeparator={true}
                          />
                          : ''}
                      </h1>
                      <label style={{ float: 'left', marginTop: '65px', marginLeft: '20px' }}>a Week</label>
                    </div>
                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                      <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid #000' }}>
                        <thead>
                          <tr>
                            <th style={{ color: '#fff', textAlign: 'left', backgroundColor: '#000', padding: '7px', fontSize: '14px' }}>Single Item Rental includes</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ padding: '20px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryHeaderObj.description) ? inventoryInformations.inventoryHeaderObj.description : '-'}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                      <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid #000' }}>
                        <thead >
                          <tr>
                            <th style={{ border: '1px solid #000', textAlign: 'left', backgroundColor: '#fff', width: '60%', padding: '5px', fontSize: '14px' }}>Description</th>
                            <th style={{ border: '1px solid #000', textAlign: 'left', backgroundColor: '#fff', width: '40%', padding: '5px', fontSize: '14px' }}>Condition</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000' }}>Serial : {validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.serialNumber) ? inventoryInformations.inventoryInformationObj.serialNumber : ''}</td>
                            <td style={{ padding: '5px', fontSize: '14px' }}>Based on DOR-system push</td>
                          </tr>
                          <tr>
                            <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000' }}>Item : {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryHeaderObj.itemNumber) ? inventoryInformations.inventoryHeaderObj.itemNumber : ''}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000' }}>Model : {validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.modelNumber) ? inventoryInformations.inventoryInformationObj.modelNumber : ''}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000', display: 'flex' }}><span style={{ paddingTop: '27px' }}>Barcode :</span>
                              <span><Barcode fontSize={10} value={generateBarCodeContent(inventoryInformations?.inventoryInformationObj?.inventoryPriceId, inventoryInformations?.inventoryInformationObj?.deptRefCode, inventoryInformations?.inventoryInformationObj?.subDeptRefCode)} displayValue={true} height={25} width={1} />{' '}</span>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div style={{ width: '100%', float: 'left', borderBottom: '2px solid #000', paddingBottom: '10px', marginBottom: '20px' }}>
                      <div style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px' }}>
                        <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid #000' }}>
                          <tbody>
                            <tr>
                              <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000' }}>Weekly Rental Rate</td>
                              <td style={{ padding: '5px', fontSize: '14px' }}>
                                {validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.currentRate) ?
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={roundOff(inventoryInformations.inventoryInformationObj.currentRate, 3).toFixed(2)}
                                    prefix="$ "
                                    className={classes.formLabelValue}
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                  />
                                  : ''}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000' }}>Monthly Rental Rate</td>
                              <td style={{ padding: '5px', fontSize: '14px' }}>{validateObject(inventoryInformations?.inventoryInformationObj.currentRate) ?
                                <NumberFormat
                                  thousandsGroupStyle="thousand"
                                  value={roundOff((inventoryInformations?.inventoryInformationObj.currentRate * 4.3333), 3).toFixed(2)}
                                  prefix="$ "
                                  className={classes.formLabelValue}
                                  displayType="text"
                                  type="text"
                                  thousandSeparator={true}
                                />
                                : ''}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                      <div style={{ width: '100%', float: 'left' }}>
                        <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                        <label style={{ marginBottom: '10px' }}> Repair nicks or scratches</label>
                      </div>

                      <div style={{ width: '100%', float: 'left' }}>
                        <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                        <label style={{ marginBottom: '10px' }}> Refurbish</label>
                      </div>

                      <div style={{ width: '100%', float: 'left' }}>
                        <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                        <label style={{ marginBottom: '10px' }}> Inspect for missing parts (e.g. remotes, cables, hoses, etc.) </label>
                      </div>

                      <div style={{ width: '100%', float: 'left' }}>
                        <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                        <label style={{ marginBottom: '10px' }}> Clean and inspect</label>
                      </div>

                      <div style={{ width: '100%', float: 'left' }}>
                        <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                        <label style={{ marginBottom: '10px' }}> System restore (e.g. laptops, tablets, Xbox, etc.)</label>
                      </div>
                    </div>
                    <div style={{ width: '100%', float: 'left', marginBottom: '30px' }}>
                      <label style={{ width: '100%', float: 'left', marginBottom: '10px' }}>Select a proposed condition:</label>

                      <div style={{ width: '100%', float: 'left' }}>
                        <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                        <label style={{ marginBottom: '10px' }}> Like New</label>
                      </div>

                      <div style={{ width: '100%', float: 'left' }}>
                        <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                        <label style={{ marginBottom: '10px' }}> Good</label>
                      </div>

                      <div style={{ width: '100%', float: 'left' }}>
                        <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                        <label style={{ marginBottom: '10px' }}> Fair </label>
                      </div>

                      <div style={{ width: '100%', float: 'left' }}>
                        <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                        <label style={{ marginBottom: '10px' }}> Poor</label>
                      </div>

                      <div style={{ width: '100%', float: 'left' }}>
                        <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                        <label style={{ marginBottom: '10px' }}> Requires service</label>
                      </div>

                      <div style={{ width: '100%', float: 'left' }}>
                        <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                        <label style={{ marginBottom: '10px' }}> Cash sale</label>
                      </div>
                    </div>
                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                      <div style={{ width: '30%', float: 'left' }}>
                        <label style={{ float: 'left' }}>Signature :</label>
                        <span style={{ borderBottom: '2px solid #000', width: '50%', float: 'left', marginTop: '20px', marginLeft: '10px' }}></span>
                      </div>

                      <div style={{ width: '30%', float: 'left' }}>
                        <label style={{ float: 'left' }}>Date :</label>
                        <span style={{ borderBottom: '2px solid #000', width: '50%', float: 'left', marginTop: '20px', marginLeft: '10px' }}></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.hide}>
                <div id="transferFormInnerHtml">
                  <div style={{ float: 'left', width: '94%', padding: '3%' }}>

                    <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
                      <h2 style={{ float: 'left', margin: '0px' }}>Transfer Form</h2>
                      <div style={{ float: 'right' }}>{moment().format('ddd,D MMM YYYY, hh:mm A')}</div>
                    </div>

                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                      <label style={{ float: 'left', margin: '0px', fontSize: '20px' }}>{validateObject(storeNo) ? storeNo : '-'}</label>
                      <label style={{ float: 'right' }}>Page: 1</label>
                    </div>

                    <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
                      <label style={{ margin: '0px' }}>Transferring Store: {validateObject(storeNo) ? storeNo : '-'}</label>
                    </div>
                    <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
                      <label style={{ margin: '0px' }}>Receiving Store: {validateObject(receivingStore) ? receivingStore : ' - '}</label>
                      {/* <label style={{ margin: '0px' }}>Receiving Store: {validateObject(transferState) && transferState != 'Select' ? transferState : validateObject(transfer2Store) && transfer2Store != '' ? transfer2Store : ''}</label> */}
                    </div>

                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                          <tr>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Item</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Model</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Vendor</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Brand</th>
                            <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>MFG Serial #</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryHeaderObj.itemNumber) ? inventoryInformations.inventoryHeaderObj.itemNumber : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.modelNumber) ? inventoryInformations.inventoryInformationObj.modelNumber : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryDetails.depreciationEstimatorObj) && validateObject(inventoryDetails.depreciationEstimatorObj.vendor) ? inventoryDetails.depreciationEstimatorObj.vendor : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryDetails.depreciationEstimatorObj) && validateObject(inventoryDetails.depreciationEstimatorObj.brand) ? inventoryDetails.depreciationEstimatorObj.brand : '-'}</td>
                            <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.serialNumber) ? inventoryInformations.inventoryInformationObj.serialNumber : '-'}</td>
                          </tr>
                          <tr />
                        </tbody>
                      </table>
                    </div>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center', marginBottom: '70px', marginTop: '70px' }}>
                      <div style={{ width: '100%', fontSize: '25px' }}>I Am Sold!</div>
                      <div style={{ width: '100%', fontSize: '25px' }}>Please ask to see another</div>
                      <div style={{ width: '100%', fontSize: '25px' }}>One just like me!</div>
                    </div>
                    <div style={{ width: '100%', float: 'left' }}>
                      <div style={{ width: '50%', float: 'left' }}>
                        <h4>Delivered By</h4>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                        </div>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                        </div>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Signature</div>
                        </div>
                      </div>

                      <div style={{ width: '50%', float: 'left' }}>
                        <h4>Received By</h4>
                        <div style={{ width: '70%', float: 'left', marginBottom: '30px' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                        </div>
                        <div style={{ width: '30%', float: 'left', marginBottom: '30px' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '70%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Date</div>
                        </div>

                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                        </div>
                        <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                          <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                          <div style={{ width: '100%', float: 'left' }}>Signature</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
      }
    </>
  );
}
export default Inventoryinfo;